import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { store } from './store';
import './styles.css';
import theme from './theme';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { SttTranslateHook } from '@stt-componentes/core';
import Home from './paginas';


const App = () => {
    return (
        <div>
            <NotificationContainer />
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <SttTranslateHook.I18nContextProvider modulo={'estomato'}>
                        <Home />
                    </SttTranslateHook.I18nContextProvider>
                </ThemeProvider>
            </Provider>
        </div>
    );
}

export default App;