import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Pesquisa from './pesquisa';
import Visualizacao from './visualizacao/index';
import Utils from '../../utils';
import { useSignals } from '@preact/signals-react/runtime';
import carregando from '../../signals/carregando';
import { SttTranslateHook } from '@stt-componentes/core';

const Aba = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { ...other } = props;

    useSignals();

    const loading = (open) => {
        carregando.value = { open: open, text: strings.gerandoDocumento };
    }

    const imprimir = (idExame) => {
        loading(true);
        Utils.imprimirFn({ id: idExame }, () => loading(false));
    }

    const imprimirProtocolo = (exame) => {
        loading(true);
        Utils.imprimirProtocoloFn(exame, () => loading(false));
    }

    const imprimirTermo = (exame) => {
        loading(true);
        Utils.imprimirTcleFn({ id: exame.id_paciente }, () => loading(false));
    }

    return (
        <>
            <Routes>
                <Route path="/visualizar/:id" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/visualizar" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/" element={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
            </Routes>
        </>
    );

}

export default Aba;