import * as yup from 'yup'
import { FIELDS } from './fieldNames';
import { TABAGISMO, OUTRAS_DROGAS, ETILISMO } from '../../../../common/Constants';

export default (strings) => {
   return yup.object().shape({
        [FIELDS.TABAGISMO]: yup.string().nullable().required(strings.campoObrigatorio), 
        [FIELDS.TIPO_FUMO]: yup.array().when(FIELDS.TABAGISMO, {
            is: (val) => val === TABAGISMO.FUMANTE || val === TABAGISMO.EX_FUMANTE,
            then: yup.array().of(yup.number()).required(strings.campoObrigatorio)
        }),
        [FIELDS.TABAGISMO_CIGARROS_DIA]: yup.number().when(FIELDS.TABAGISMO, {
            is: (val) => val === TABAGISMO.FUMANTE || val === TABAGISMO.EX_FUMANTE,
            then: yup.number().typeError(strings.tabagismoCigarrosDiaInvalido).min(1).required(strings.campoObrigatorio)
        }),
        [FIELDS.TABAGISMO_ANOS_FUMO]: yup.number().when(FIELDS.TABAGISMO, {
            is: (val) => val === TABAGISMO.FUMANTE || val === TABAGISMO.EX_FUMANTE,
            then: yup.number().typeError(strings.tabagismoCigarrosDiaInvalido).min(1).max(99).required(strings.campoObrigatorio)
        }),
        [FIELDS.ETILISMO]: yup.number().min(0).max(4).nullable().required(strings.campoObrigatorio),
        [FIELDS.TIPO_BEBIDA]: yup.array().when(FIELDS.ETILISMO, {
            is: (val) => val !== ETILISMO.NUNCA,
            then: yup.array().of(yup.number()).required(strings.campoObrigatorio)
        }),
        [FIELDS.EXPOSICAO_SOL_HORAS_DIA]: yup.object()
            .shape({
                identificador: yup.number(),
                descricao: yup.string(),
                ordenacao: yup.number()
            })
            .nullable()
            .required(strings.campoObrigatorio),
        [FIELDS.EXPOSICAO_SOL_MESES_ANOS]: yup.object()
            .shape({
                identificador: yup.number(),
                descricao: yup.string(),
                ordenacao: yup.number()
            })
            .nullable()
            .required(strings.campoObrigatorio),
        [FIELDS.PROTECAO_SOLAR_TEMPO]: yup.object()
            .shape({
                identificador: yup.number(),
                descricao: yup.string(),
                ordenacao: yup.number()
            })
            .nullable()
            .required(strings.campoObrigatorio),
        [FIELDS.PROTECAO_SOLAR_FREQUENCIA]: yup.object()
            .shape({
                identificador: yup.number(),
                descricao: yup.string(),
                sigla: yup.string(),
                ordenacao: yup.number()
            })
            .nullable()
            .required(strings.campoObrigatorio),
        [FIELDS.OUTRAS_DROGAS]: yup.string().nullable().required(strings.campoObrigatorio),
        [FIELDS.OUTRAS_DROGAS_TIPO]: yup.string().when(FIELDS.OUTRAS_DROGAS, {
            is: (val) => val !== OUTRAS_DROGAS.NAO_USUARIO,
            then: yup.string().required(strings.campoObrigatorio)
        }),
        [FIELDS.OUTRAS_DROGAS_ANOS_USO]: yup.number().when(FIELDS.OUTRAS_DROGAS, {
            is: (val) => val !== OUTRAS_DROGAS.NAO_USUARIO,
            then: yup.number().min(1).max(99).required(strings.campoObrigatorio)
        })
    });
};