export const QUEIXA_PRINCIPAL = 'queixaPrincipal';
export const SEM_LESAO = 'semLesao';
export const LESOES = 'lesoes';
export const ESTRUTURA_AFETADA = 'estruturaAfetada';
export const LESAO_FUNDAMENTAL = 'lesaoFundamental';
export const COMPLEMENTO_LESAO_FUNDAMENTAL = 'complementoLesaoFundamental';
export const LOCALIZACAO = 'localizacao';
export const TAMANHO = 'tamanho';
export const ALTURA = 'altura';
export const LARGURA = 'largura';
export const UNIDADE_MEDIDA = 'unidadeMedida';
export const COR = 'cor';
export const OUTRA_COR = 'outraCor';
export const SUPERFICIE = 'superficie';
export const OBSERVACAO_SUPERFICIE = 'observacaoSuperficie';
export const PALPACAO = 'palpacao';
export const TEMPO_EVOLUCAO = 'tempoEvolucao';
export const TEMPO = 'tempo';
export const TEMPO_EVOLUCAO_NAO_CONHECIDO = 'tempoEvolucaoNaoConhecido';
export const TIPO_CRESCIMENTO = 'tipoCrescimento';
export const DOR = 'dor';
export const PACIENTE_ASSINTOMATICO = 'pacienteAssintomatico';
export const LOCALIZACAO_DOR = 'localizacaoDor';
export const ESPONTANEIDADE_DOR = 'espontaneidadeDor';
export const INTENSIDADE_DOR = 'intensidadeDor';
export const DURACAO_DOR = 'duracaoDor';
export const TIPO_DOR = 'tipoDor';
export const QUEIMACAO = 'queimacao';
export const SENSIBILIDADE = 'sensibilidade';
export const LESAO_SAI_RASPAGEM = 'lesaoSaiRaspagem';
export const REGIAO_AFETADA = 'regiaoAfetada';
export const LADO_AFETADO = 'ladoAfetado';
export const ARCADA_AFETADA = 'arcadaAfetada';
export const OCORRENCIA_LESAO = 'ocorrenciaLesao';
