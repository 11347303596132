import * as yup from 'yup'
import { 
    QUEIXA_PRINCIPAL,
    COMPLEMENTO_LESAO_FUNDAMENTAL, 
    LESAO_FUNDAMENTAL, 
    LESOES, 
    LOCALIZACAO, 
    OUTRA_COR, 
    SEM_LESAO, 
    COR, 
    SUPERFICIE,
    PALPACAO,
    TEMPO_EVOLUCAO,
    TEMPO_EVOLUCAO_NAO_CONHECIDO,
    TEMPO,
    UNIDADE_MEDIDA,
    PACIENTE_ASSINTOMATICO,
    LOCALIZACAO_DOR,
    ESPONTANEIDADE_DOR,
    DURACAO_DOR,
    INTENSIDADE_DOR,
    TIPO_DOR,
    QUEIMACAO,
    SENSIBILIDADE,
    LESAO_SAI_RASPAGEM,
    TAMANHO,
    ALTURA,
    LARGURA
 } from './fieldNames'


export default (strings) => {
    return yup.object().shape({
        [QUEIXA_PRINCIPAL]: yup.string().trim().required(strings.campoObrigatorio),
        [LESOES]: yup.array().nullable().when(SEM_LESAO, {
            is: false,
            then: yup
                .array()
                .of(yup
                    .object()
                    .shape({
                        [LESAO_FUNDAMENTAL]: yup.number().required(strings.campoObrigatorio),
                        [COMPLEMENTO_LESAO_FUNDAMENTAL]: yup.string().when(LESAO_FUNDAMENTAL, {
                            is: val => val === 14, // TODO ver pelo atributo "complemento" da opção selecionada
                            then: yup.string().required(strings.campoObrigatorio)
                        }),
                        [LOCALIZACAO]: yup.number().required(strings.campoObrigatorio),
                        [TAMANHO]: yup.object().shape({
                            [ALTURA]: yup.number().nullable().required(strings.campoObrigatorio),
                            [LARGURA]: yup.number().nullable().required(strings.campoObrigatorio),
                            [UNIDADE_MEDIDA]: yup.string().nullable().required(strings.campoObrigatorio)
                        }),
                        [COR]: yup.number().required(strings.campoObrigatorio),
                        [OUTRA_COR]: yup.string().when(COR, {
                            is: val => val === 9, // TODO ver pelo atributo "complemento" da opção selecionada
                            then: yup.string().required(strings.campoObrigatorio)
                        }),
                        [SUPERFICIE]: yup.number().required(strings.campoObrigatorio),
                        [PALPACAO]: yup.number().required(strings.campoObrigatorio),
                        [TEMPO_EVOLUCAO]: yup.object().when(TEMPO_EVOLUCAO_NAO_CONHECIDO, {
                            is: false,
                            then: yup.object().shape({
                                [TEMPO]: yup.number().required(strings.campoObrigatorio),
                                [UNIDADE_MEDIDA]: yup.object().nullable().required(strings.campoObrigatorio)
                            }).required(strings.campoObrigatorio)
                        }),
                        [LOCALIZACAO_DOR]: yup.string().when(PACIENTE_ASSINTOMATICO, {
                            is: false,
                            then: yup.string().required(strings.campoObrigatorio)
                        }),
                        [ESPONTANEIDADE_DOR]: yup.string().when(PACIENTE_ASSINTOMATICO, {
                            is: false,
                            then: yup.string().required(strings.campoObrigatorio)
                        }),
                        [DURACAO_DOR]: yup.string().when(PACIENTE_ASSINTOMATICO, {
                            is: false,
                            then: yup.string().required(strings.campoObrigatorio)
                        }),
                        [INTENSIDADE_DOR]: yup.string().when(PACIENTE_ASSINTOMATICO, {
                            is: false,
                            then: yup.string().required(strings.campoObrigatorio)
                        }),
                        [TIPO_DOR]: yup.string().when(PACIENTE_ASSINTOMATICO, {
                            is: false,
                            then: yup.string().required(strings.campoObrigatorio)
                        }),
                        [QUEIMACAO]: yup.string().when(PACIENTE_ASSINTOMATICO, {
                            is: false,
                            then: yup.string().required(strings.campoObrigatorio)
                        }),
                        [SENSIBILIDADE]: yup.string().when(PACIENTE_ASSINTOMATICO, {
                            is: false,
                            then: yup.string().required(strings.campoObrigatorio)
                        }),
                        [LESAO_SAI_RASPAGEM]: yup.string().when(PACIENTE_ASSINTOMATICO, {
                            is: false,
                            then: yup.string().required(strings.campoObrigatorio)
                        })
                    }).required(strings.campoObrigatorio)
                ).min(1)
        })
    });
}