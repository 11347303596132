import * as yup from 'yup'
import { FIELDS } from './fieldNames';
import { POSSIBILIDADE_TRAUMA_LOCAL } from '../../../../common/Constants';

export default (strings) => {
   return yup.object().shape({
        [FIELDS.COMPLEMENTO_TRAUMA_LOCAL]: yup.string().when(FIELDS.POSSIBILIDADE_TRAUMA_LOCAL, {
            is: val => val === POSSIBILIDADE_TRAUMA_LOCAL.OUTRA,
            then: yup.string().max(255).required(strings.campoObrigatorio)
        })
    });
};