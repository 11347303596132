import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { PERMISSOES } from '../common/Constants';
import Erro from './erro';
import Menu from '../componentes/menu';
import Erro401 from './erro/401';
import { SttCircularProgress, SttCacheManager, SttAlerta, SttLoading } from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import PaginaInicial from './inicio';
import Solicitacao from './solicitacao';
import Exames from './exame';
import Laudo from './laudo';
import LaudoGrave from './laudos-graves';
import EnvioImagens from './envio-imagens';
import ProtectedRoute from './protectedRoute';
import { useSignals } from '@preact/signals-react/runtime';
import usuario from '../signals/usuario';
import alerta from '../signals/alerta';
import carregando from '../signals/carregando';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Home = () => {
    useSignals();
    const classes = useStyles();

    return (
        <BrowserRouter basename={`/${global.gConfig.basename}`}>
            {
                usuario.value.autenticado
                ? 
                <div className={classes.wrapper}>
                    
                        <Menu />
                        <Routes>
                            <Route path="/nao-autorizado" exact element={<Erro401 />} />
                            <Route path="/erro" exact element={<Erro />} />
                            <Route path="/solicitacao" exact element={<ProtectedRoute component={Solicitacao} permissao={PERMISSOES.SOLICITAR_EXAME} />} />
                            <Route path="/imagens" exact element={<ProtectedRoute component={EnvioImagens} permissao={PERMISSOES.VISUALIZAR_SOLICITACAO} />} />
                            <Route path="/exames/*" exact element={<ProtectedRoute component={Exames} permissao={PERMISSOES.VISUALIZAR_EXAME} />} />
                            <Route path="/laudo" exact element={<ProtectedRoute component={Laudo} permissao={PERMISSOES.LAUDAR_EXAME_FABRICA} />} />
                            <Route path="/laudos/graves" exact element={<ProtectedRoute component={LaudoGrave} permissao={PERMISSOES.ESTOMATO} />} /> 
                            <Route path="/" exact element={<PaginaInicial />} />
                        </Routes>
                        <SttAlerta {...alerta.value} />
                        <SttLoading {...carregando.value} />
                </div>
                :
                <>
                    <SttCacheManager config={global.gConfig} onSync={loaded => {
                        usuario.value = { ...Cache.getUserInfo(), autenticado: loaded };
                    }} hasExternalToken={true} />
                    <div className={classes.loading}>
                        <SttCircularProgress />
                    </div>
                </>
            }
        </BrowserRouter>
    );
};

export default Home;
