import React, { useRef, memo, lazy, Suspense, useContext } from 'react';
import { Formik } from 'formik';
import { PERMISSOES } from '../../common/Constants';

import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import { MEDICO_SOLICITANTE, INSTITUICAO_SOLICITANTE } from '../../componentes/solicitacao/solicitante/fieldNames';
import { FIELDS as FIELDS_HABITOS } from '../../componentes/solicitacao/habitos/form/fieldNames';
import { FIELDS as FIELDS_TRATAMENTO_PREVIO } from '../../componentes/solicitacao/tratamento-previo/form/fieldNames';
import { FIELDS as FIELDS_OBSERVACOES } from '../../componentes/solicitacao/observacoes/form/fieldNames';

import axios from 'axios';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import { temPermissaoRBAC } from '../../secutity/acl';
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttButton,
    SttLoading,
    SttFormHelperText,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';
import usuario from '../../signals/usuario';
import alerta from '../../signals/alerta';
import { solicitacao } from '../../signals/envio-imagens';
import { initialValues } from './initialValues';

import { makeStyles } from '@material-ui/core/styles';

import EnvioImagens from '../../componentes/envio-imagens';
import { LESOES, QUEIXA_PRINCIPAL } from '../../componentes/solicitacao/lesoes/fieldNames';
import Utils from '../../utils';

const Paciente = lazy(() => import('@stt-componentes/paciente'));
const Solicitante = lazy(() => import('../../componentes/solicitacao/solicitante'));
const Lesoes = lazy(() => import('../../componentes/solicitacao/lesoes'));
const Habitos = lazy(() => import('../../componentes/solicitacao/habitos'));
const TratamentoPrevio = lazy(() => import('../../componentes/solicitacao/tratamento-previo'));
const Observacoes = lazy(() => import('../../componentes/solicitacao/observacoes'));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    }
}));

const camposFormPaciente = {
    camposBusca: { 
        cpf: { obrigatorio: false }, 
        cns: { obrigatorio: false }
    },
    camposCadastro: [
        [
            { nome: 'nome', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            { nome: 'genero', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
            { nome: 'dataNascimento', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
        ],
        [
            { nome: 'cpf', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'cns', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'cep', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
        ],
        [
            { nome: 'logradouro', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 7, md: 7 } },
            { nome: 'bairro', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 5, md: 5 } },
        ],
        [
            { nome: 'pais', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'uf', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            { nome: 'municipio', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
        ],
        [
            { nome: 'tipoContato1', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            { nome: 'contato1', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
        ],
        [
            { nome: 'peso', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            { nome: 'altura', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
        ]
    ]
};

//Envio de imagens
const enviarImagens = signal(false);

// Seções do formulário
const secaoPacienteAberta = signal(true);
const secaoLesoesAberta = signal(false);
const secaoSolicitanteAberta = signal(false);
const secaoHabitosAberta = signal(false);
const secaoTratamentoPrevioAberta = signal(false);
const secaoObservacoesAdicionaisLesaoAberta = signal(false);

const Solicitacao = () => {
    useSignals();
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;
    const schema = validationSchema(strings, camposFormPaciente.camposCadastro);

    /**
     * Gera o termo de autorização
     * 
     */
     const imprimirTermo = () => {
         const { id_paciente } = solicitacao.value.dados;
        Utils.imprimirTcleFn({ id: id_paciente }, () => {});
    }

    const abrirSecaoPaciente = (estado) => {
        secaoPacienteAberta.value = estado;
        secaoLesoesAberta.value = false;
        secaoSolicitanteAberta.value = false;
        secaoHabitosAberta.value = false;
        secaoTratamentoPrevioAberta.value = false;
        secaoObservacoesAdicionaisLesaoAberta.value = false;
    }
    const abrirSecaoLesoes = (estado) => {
        secaoLesoesAberta.value = estado;
        secaoPacienteAberta.value = false;
        secaoSolicitanteAberta.value = false;
        secaoHabitosAberta.value = false;
        secaoTratamentoPrevioAberta.value = false;
        secaoObservacoesAdicionaisLesaoAberta.value = false;
    }
    const abrirSecaoSolicitante = (estado) => {
        secaoPacienteAberta.value = false;
        secaoLesoesAberta.value = false;
        secaoSolicitanteAberta.value = estado;
        secaoHabitosAberta.value = false;
        secaoTratamentoPrevioAberta.value = false;
        secaoObservacoesAdicionaisLesaoAberta.value = false;
    }
    const abrirSecaoHabitos = (estado) => {
        secaoPacienteAberta.value = false;
        secaoLesoesAberta.value = false;
        secaoSolicitanteAberta.value = false;
        secaoHabitosAberta.value = estado;
        secaoTratamentoPrevioAberta.value = false;
        secaoObservacoesAdicionaisLesaoAberta.value = false;
    }
    const abrirSecaoTratamentoPrevio = (estado) => {
        secaoPacienteAberta.value = false;
        secaoLesoesAberta.value = false;
        secaoSolicitanteAberta.value = false;
        secaoHabitosAberta.value = false;
        secaoTratamentoPrevioAberta.value = estado;
        secaoObservacoesAdicionaisLesaoAberta.value = false;
    }
    const abrirSecaoObservacoesAdicionaisLesao = (estado) => {
        secaoPacienteAberta.value = false;
        secaoLesoesAberta.value = false;
        secaoSolicitanteAberta.value = false;
        secaoHabitosAberta.value = false;
        secaoTratamentoPrevioAberta.value = false;
        secaoObservacoesAdicionaisLesaoAberta.value = estado;
    }

    const secaoPaciente = useRef(null);
    const secaoLesoes = useRef(null);
    const secaoSolicitante = useRef(null);
    const secaoHabitos = useRef(null);
    const secaoTratamentoPrevio = useRef(null);
    const secaoObservacoesAdicionaisLesao = useRef(null);

    const confirmarTermo = () => {
        const alertConfig = {
            title: strings.termoEsclarecimento,
            message: strings.mensagemConfirmarTermo_solicitacao,
            type: 'confirmation',
            open: true,
            options: [
                {
                    title: strings.sim,
                    onClick: () => {
                        alerta.value = { ...alerta.value, open: false };
                        imprimirTermo();
                        if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
                            confirmarEnvioImagens();
                        }
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        alerta.value = { ...alerta.value, open: false };
                        if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
                            confirmarEnvioImagens();
                        }
                    }
                }
            ],
            onClose: () => {
                alerta.value = { ...alerta.value, open: false };
                if (temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME)) {
                    confirmarEnvioImagens();
                }
            }
        };
        alerta.value = alertConfig;
    }

    const confirmarEnvioImagens = () => {
        const alertConfig = {
            title: strings.tituloConfirmarEnvioExame,
            message: strings.mensagemConfirmarEnvioExame,
            type: 'confirmation',
            open: true,
            options: [
                {
                    title: strings.sim,
                    onClick: () => {
                        alerta.value = { ...alerta.value, open: false };
                        enviarImagens.value = true;
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        alerta.value = { ...alerta.value, open: false };
                    }
                }
            ],
            onClose: () => {
                alerta.value = { ...alerta.value, open: false };
            }
        };
        alerta.value = alertConfig;
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    let dados = { ...data };
                    dados.digitador = {
                        id: usuario.idFuncionario
                    }
                    if (dados.paciente) {
                        if (dados.paciente.cpf) {
                            dados.paciente.cpf = dados.paciente.cpf.replace(/\D+/g, '');
                        }
                        if (dados.paciente.cep) {
                            dados.paciente.cep = dados.paciente.cep.replace(/\D+/g, '');
                        }
                    }

                    axios
                        .post(`${ESTOMATO_API_BASE_URL}/solicitacao`, dados, { headers: getHeaders() })
                        .then((response) => {

                            solicitacao.value.set(response.data.data);

                            const alertConfig = {
                                title: strings.sucesso,
                                message: response.data.message,
                                type: 'success',
                                open: false,
                                options: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            alerta.value = { ...alerta.value, open: false }
                                            abrirSecaoPaciente(true);
                                            confirmarTermo();
                                        }
                                    }
                                ]
                            };
                            alerta.value = alertConfig;
                            resetForm();
                        })
                        .catch(err => {
                            let tituloAlerta = '';
                            let mensagemAlerta = '';
                            const { response } = err;
                            let msg = strings.mensagemErroGeral;
                            if (response) {
                                if (response.status === HttpStatus.BAD_REQUEST) {
                                    const dadosResposta = response.data;
                                    let arrMensagem = [];
                                    dadosResposta.errors.forEach(error => {
                                        arrMensagem.push(`- ${error.message}`);
                                    });
                                    msg = arrMensagem.join('\n');
                                    tituloAlerta = dadosResposta.message;
                                    mensagemAlerta = msg;
                                } else {
                                    tituloAlerta = strings.erro;
                                    mensagemAlerta = msg;
                                }
                            } else {
                                tituloAlerta = strings.erro;
                                mensagemAlerta = msg;
                            }
                            const alertConfig = {
                                title: tituloAlerta,
                                message: mensagemAlerta,
                                type: 'error',
                                open: false,
                                options: [
                                    {
                                        title: strings.ok,
                                        onClick: () => alerta.value = { ...alerta.value, open: false }
                                    }
                                ]
                            };
                            alerta.value = alertConfig;
                        })
                        .finally(() => {
                            setSubmitting(false);
                            alerta.value = { ...alerta.value, open: true };
                        });
                }}
            >
                {
                    ({
                        values,
                        isSubmitting,
                        handleSubmit,
                        errors,
                        touched,
                        submitCount,
                        setFieldValue
                    }) => {
                        return (
                            <SttContainer>
                                <form onSubmit={handleSubmit} noValidate>
                                    {/* Dados do paciente */}
                                    <SttExpansionPanel
                                        title={strings.dadosPaciente}
                                        opened={secaoPacienteAberta.value}
                                        callback={estadoInterno => abrirSecaoPaciente(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoPaciente}></div>
                                                <Paciente 
                                                    strings={strings}
                                                    headers={getHeaders()} 
                                                    usarTipoContato
                                                    persistirParametrosBusca
                                                    campos={camposFormPaciente} 
                                                    imc
                                                    formExterno={{
                                                        paciente: values[PACIENTE],
                                                        setFieldValue,
                                                        errors: errors[PACIENTE],
                                                        submitCount
                                                    }} 
                                                />

                                                {
                                                    touched.paciente && errors.paciente && errors.paciente.nome &&
                                                    <SttFormHelperText error>
                                                        {strings.pacienteObrigatorio}
                                                    </SttFormHelperText>
                                                }
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    {/* Solicitante */}
                                    <SttExpansionPanel
                                        title={strings.profissionalSolicitante}
                                        opened={secaoSolicitanteAberta.value}
                                        classegriditem={classes.fullWidth}
                                        callback={estadoInterno => abrirSecaoSolicitante(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoSolicitante}></div>
                                                <Solicitante />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    {/* Lesoes */}
                                    <SttExpansionPanel
                                        title={strings.lesoes}
                                        opened={secaoLesoesAberta.value}
                                        classegriditem={classes.fullWidth}
                                        callback={estadoInterno => abrirSecaoLesoes(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoLesoes}></div>
                                                <Lesoes />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />
                                    {/* Hábitos */}
                                    <SttExpansionPanel
                                        title={strings.habitos}
                                        opened={secaoHabitosAberta.value}
                                        callback={estadoInterno => abrirSecaoHabitos(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoHabitos}></div>
                                                <Habitos />
                                            </Suspense>
                                        }
                                    />
                                    {/* Tratamento prévio */}
                                    <SttExpansionPanel
                                        title={strings.tratamentoPrevio}
                                        opened={secaoTratamentoPrevioAberta.value}
                                        callback={estadoInterno => abrirSecaoTratamentoPrevio(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoTratamentoPrevio}></div>
                                                <TratamentoPrevio />
                                            </Suspense>
                                        }
                                    />
                                    {/* Observações */}
                                    <SttExpansionPanel
                                        title={strings.observacoesAdicionaisLesao}
                                        opened={secaoObservacoesAdicionaisLesaoAberta.value}
                                        callback={estadoInterno => abrirSecaoObservacoesAdicionaisLesao(estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoObservacoesAdicionaisLesao}></div>
                                                <Observacoes />
                                            </Suspense>
                                        }
                                    />

                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        nomarginleft="true"
                                        onClick={() => {
                                            if (!errors) {
                                                return;
                                            }
                                            let node;
                                            if (errors[PACIENTE]) {
                                                abrirSecaoPaciente(true);
                                                node = secaoPaciente.current;
                                            } else if (errors[MEDICO_SOLICITANTE] || errors[INSTITUICAO_SOLICITANTE]) {
                                                abrirSecaoSolicitante(true);
                                                node = secaoSolicitante.current;
                                            } else if (errors[QUEIXA_PRINCIPAL] || errors[LESOES]) {
                                                abrirSecaoLesoes(true);
                                                node = secaoLesoes.current;
                                            } else if (errors[FIELDS_HABITOS.TABAGISMO] || 
                                                errors[FIELDS_HABITOS.TIPO_FUMO] || 
                                                errors[FIELDS_HABITOS.TABAGISMO_CIGARROS_DIA] || 
                                                errors[FIELDS_HABITOS.TABAGISMO_ANOS_FUMO] || 
                                                errors[FIELDS_HABITOS.ETILISMO] || 
                                                errors[FIELDS_HABITOS.TIPO_BEBIDA] || 
                                                errors[FIELDS_HABITOS.OUTRAS_DROGAS] ||
                                                errors[FIELDS_HABITOS.OUTRAS_DROGAS_TIPO] || 
                                                errors[FIELDS_HABITOS.OUTRAS_DROGAS_ANOS_USO] ||
                                                errors[FIELDS_HABITOS.EXPOSICAO_SOL_HORAS_DIA] ||
                                                errors[FIELDS_HABITOS.EXPOSICAO_SOL_MESES_ANOS] ||
                                                errors[FIELDS_HABITOS.PROTECAO_SOLAR_TEMPO] ||
                                                errors[FIELDS_HABITOS.PROTECAO_SOLAR_FREQUENCIA]) {
                                                abrirSecaoHabitos(true);
                                                node = secaoHabitos.current;
                                            } else if (errors[FIELDS_TRATAMENTO_PREVIO.TRATAMENTO_PREVIO_TIPO]) {
                                                abrirSecaoTratamentoPrevio(true);
                                                node = secaoTratamentoPrevio.current;
                                            } else if (errors[FIELDS_OBSERVACOES.COMPLEMENTO_TRAUMA_LOCAL]) {
                                                abrirSecaoObservacoesAdicionaisLesao(true);
                                                node = secaoObservacoesAdicionaisLesao.current;
                                            }
                                            
                                            if (node) {
                                                setTimeout(() => {
                                                    node.scrollIntoView({
                                                        behavior: 'smooth',
                                                        block: 'center',
                                                        inline: 'start'
                                                    });
                                                }, 500);
                                            }
                                        }}
                                    >
                                        {strings.enviar}
                                    </SttButton>
                                </form>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoSolicitacao}
                                />
                            </SttContainer>
                        )
                    }
                }
            </Formik>
            {
                enviarImagens.value && solicitacao.value.dados && temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME) &&
                <EnvioImagens 
                    enviar={enviarImagens.value} 
                    resetFormulario={() => {
                        enviarImagens.value = false;
                    }}
                    dadosSolicitacao={solicitacao.value.dados}
                />
            }
        </>
    );
};

export default Solicitacao;