import React, { useContext } from 'react';
import { Field, FastField, FieldArray, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import {
    ARQUIVO, 
    LESAO, 
    DATA_AQUISICAO,
    LESAO_FUNDAMENTAL
} from './fieldNames';
import { 
    SttGrid, 
    SttTranslateHook, 
    SttFileSelect,
    SttButton,
    SttDatePicker
} from '@stt-componentes/core';
import { useMoment } from '../../../hooks';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const useStyles = makeStyles(theme => ({
    outerContainer: {
        marginTop: theme.spacing(1)
    },
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },
    botaoMenos: {
        minWidth: 0
    },
    divAnexo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        minHeight: '250px'
    },
    imgAtual: {
        maxHeight: '320px',
        maxWidth: '320px',
        margin: 'auto'
    }
}));

const Form = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const { lesao } = props;
    const { values, setFieldValue } = useFormikContext();
    const moment = useMoment();

    const validarAnexo = (value) => {
        let error;
        if (!value || !(value instanceof File)) {
            error = strings.campoObrigatorio;
        } else if (!['image/jpg', 'application/pdf'].includes(value.type)) {
            error = strings.anexoInvalido;
        }
        return error;
    }

    const validarData = (value) => {
        let error;
        const inputDate = moment(value);
        const today = moment();
        if (!value) {
            error = strings.campoObrigatorio;
        } else if (!inputDate.isValid()) {
            error = strings.dataInvalida;
        } else if (inputDate.isAfter(today)) {
            error = strings.erroDataFutura;
        }
        return error;        
    }

    return (
        lesao &&
        <SttGrid container spacing={3} className={classes.outerContainer}>
            <SttGrid item xs={12} md={12}>
                <FieldArray
                    name={`${LESAO}_${lesao.numero}`}
                    render={({ remove, push }) => (
                        <div>
                            {
                                values[`${LESAO}_${lesao.numero}`].map((attachment, index) => (
                                    <SttGrid container spacing={3} key={index}>
                                        <SttGrid item xs={12} md={8}>
                                            <Field name={`${LESAO}_${lesao.numero}.${index}.${ARQUIVO}`} validate={validarAnexo}>
                                                    {({
                                                        meta
                                                    }) => (
                                                        <SttFileSelect
                                                            labelInput={strings.arquivo}
                                                            onFileChange={event => {
                                                                if (event) {
                                                                    setFieldValue(`${LESAO}_${lesao.numero}.${index}.${ARQUIVO}`, event.target.files[0]);
                                                                } else {
                                                                    setFieldValue(`${LESAO}_${lesao.numero}.${index}.${ARQUIVO}`, null);
                                                                }
                                                            }}
                                                            file={attachment[ARQUIVO]}
                                                            accept={"image/jpeg,application/pdf"}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            inputprops={{
                                                                name: ARQUIVO,
                                                                label: strings.anexo,
                                                                value: attachment[ARQUIVO]?.name || '',
                                                                required: true,
                                                                error: meta.touched && meta.error
                                                            }}
                                                        />
                                                    )}
                                            </Field>
                                        </SttGrid>
                                        <SttGrid item xs={12} md={4}>
                                            <FastField name={`${LESAO}_${lesao.numero}.${index}.${DATA_AQUISICAO}`} validate={validarData}>
                                                {({
                                                    field: { name, value },
                                                    form: { setFieldValue, setFieldError, setFieldTouched },
                                                    meta
                                                }) => {
                                                    return (
                                                        <SttDatePicker
                                                            label={strings.dataAquisicao} 
                                                            inputprops={{
                                                                name: name
                                                            }}
                                                            required
                                                            disableFuture
                                                            maxDate={new Date()}
                                                            error={meta.touched && meta.error ? true : false}
                                                            onError={error => {
                                                                setFieldError(`${LESAO}_${lesao.numero}.${index}.${DATA_AQUISICAO}`, meta.error || error);
                                                            }}
                                                            value={value}
                                                            onBlur={() => {
                                                                setFieldTouched(`${LESAO}_${lesao.numero}.${index}.${DATA_AQUISICAO}`, true);
                                                            }}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            onChange={date => setFieldValue(`${LESAO}_${lesao.numero}.${index}.${DATA_AQUISICAO}`, date, true)}
                                                            onClose={() => setFieldTouched(`${LESAO}_${lesao.numero}.${index}.${DATA_AQUISICAO}`, true)}
                                                        />
                                                    );
                                                }}
                                            </FastField>
                                        </SttGrid>
                                    </SttGrid>
                                ))
                            }
                            <SttButton
                                nomarginleft="true"
                                className={classes.botaoMais}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    push({
                                        [LESAO_FUNDAMENTAL]: lesao.lesaoFundamental,
                                        [ARQUIVO]: null,
                                        [DATA_AQUISICAO]: null
                                    });
                                }}
                            >
                                +
                            </SttButton>
                            <SttButton
                                className={classes.botaoMenos}
                                variant="contained"
                                disabled={values[`${LESAO}_${lesao.numero}`].length === 1}
                                onClick={() => {
                                    let index = values[`${LESAO}_${lesao.numero}`].length - 1;
                                    remove(index);
                                }}
                                color="primary">
                                -
                            </SttButton>
                        </div>
                    )}
                />
            </SttGrid>
            <SttGrid item xs={12}>
                <Carousel statusFormatter={(current, total) => `Imagem ${current} de ${total}`}>
                    {
                        values[`${LESAO}_${lesao.numero}`].filter(value => value[ARQUIVO] && value[ARQUIVO].type !== 'application/pdf').map((value, index) => {
                            return (
                                <div className={classes.divAnexo} key={index}>
                                    <img src={URL.createObjectURL(value[ARQUIVO])} className={classes.imgAtual} />
                                    <p className="legend">{value[ARQUIVO].name}</p>
                                </div>
                            );
                        })
                    }
                </Carousel>
            </SttGrid>
        </SttGrid>
    );
}

export default Form;