import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { SttNotification, SttGrid, SttButton, SttTranslateHook } from '@stt-componentes/core';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import carregando from '../../../signals/carregando';
import Laudo from '../index';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    botao: {
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center'
    }
}));

const FabricaLaudo = (props) => {
    const { id } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;

    const idExame = useSignal(null);
    const nenhumExameLaudo = useSignal(false);
    const erro = useSignal(false);
    
    useSignals();

    const loading = (open, message = '') => {
        carregando.value = { open: open, text: message };
    }

    const carregarExame = () => {
        loading(true, strings.carregandoExame);
        idExame.value = null;

        axios
            .get(`${ESTOMATO_API_BASE_URL}/laudo/fabrica?rede=${id}`, { headers: getHeaders(), timeout: 15000 })
            .then((response) => {
                const { data } = response;
                if (!data.data.idExame) {
                    nenhumExameLaudo.value = true;
                    loading(false);
                    return;
                }
                idExame.value = data.data.idExame;

            })
            .catch(err => {
                loading(false);
                erro.value = true;
                console.error(err);
            });
    }

    useSignalEffect(() => {
        carregarExame();
    });

    return (
        <>
            {
                (erro.value && 
                <SttGrid container spacing={3}>
                    <SttGrid item xs={12}>
                    <SttNotification severity="error">
                        {strings.erroSortearExame}
                    </SttNotification>
                        <div className={classes.botao}>
                            <SttButton 
                                nomarginleft="true"
                                variant="contained" 
                                color="primary"
                                onClick={() => carregarExame()}>
                                {strings.tentarNovamente}
                            </SttButton>
                        </div>
                    </SttGrid>
                </SttGrid>) ||
                (idExame.value ? 
                    <Laudo 
                        idExame={parseInt(idExame.value)} 
                        idRede={id}
                        proximoExame={carregarExame}
                        loading={loading}
                    /> 
                    : 
                    nenhumExameLaudo.value && 
                    <SttNotification severity="info">
                        {strings.nenhummExameParaLaudo}
                    </SttNotification>)
            }
        </>
    )

}

FabricaLaudo.propTypes = {
    id: PropTypes.number.isRequired,
    tipo: PropTypes.string
};

export default FabricaLaudo;