export const initialValues = {
    paciente: {
        id: '',
        cpf: '',
        cns: '',
        nome: '',
        genero: null,
        dataNascimento: null,
        municipio: null,
        uf: null,
        pais: null,
        peso: '',
        altura: '',
        cep: '',
        logradouro: '',
        bairro: '',
        tipoContato1: null,
        contato1: ''
    },
    medicoSolicitante: null,
    instituicaoSolicitante: null,
    queixaPrincipal: '',
    semLesao: false,
    lesoes: [],
    tabagismo: undefined,
    tabagismoCigarrosDia: '',
    tabagismoAnosFumo: '',
    etilismo: undefined,
    tipoBebida: [],
    tipoFumo: [],
    exposicaoSolHorasDia: null,
    exposicaoSolMesesAno: null,
    protecaoSolarTempo: null,
    protecaoSolarFrequencia: null,
    outrasDrogas: undefined,
    outrasDrogasTipo: '',
    outrasDrogasAnosUso: '',
    tratamentoPrevio: undefined,
    tratamentoPrevioTipo: '',
    tratamentoPrevioSurtiuEfeito: undefined,
    tratamentoPrevioComplemento: '',
    usuarioProtese: undefined,
    situacaoProtese: undefined,
    abrangenciaProtese: undefined,
    possibilidadeTraumaLocal: undefined,
    complementoTraumaLocal: ''
};