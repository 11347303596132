import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from '../../request';
import axios from 'axios';
import { MODALIDADE } from '../../common/Constants';
import { SttGrid, SttButton, SttModal, SttAutocomplete, SttTranslateHook } from '@stt-componentes/core';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import alerta from '../../signals/alerta';
import carregando from '../../signals/carregando';
import { listar } from '../../signals/exame';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));
const Priorizar = (props) => {
    const { priorizar, setPriorizar, idExame, idInstituicao, callback } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    const motivosPriorizacao = useSignal([]);
    const motivo = useSignal(false);

    useSignals();

    useSignalEffect(() => {

        // Carrega a lista de motivos de priorização de exames
        if (!motivosPriorizacao.length) {
            axios
                .get(`${EXAME_API_BASE_URL}/motivo-priorizacao`, { params: { siglaModalidade: MODALIDADE.SIGLA }, headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        motivosPriorizacao.value = response.data;
                    }
                })
                .catch(err => console.log(err));
        }
    }, []);

    const fecharPriorizar = () => {
        motivo.value = null;
        setPriorizar(false);
    }

    const selecionarMotivo = (event, novoMotivo) => {
        motivo.value = novoMotivo;
    }

    const priorizarExame = () => {
        loading(true);

        const dados = {
            exame: idExame,
            motivo: motivo.value.id,
            instituicao: idInstituicao,
            modalidade: MODALIDADE.DESCRICAO_PT
        }
        axios
            .post(`${EXAME_API_BASE_URL}/priorizar`, dados, { headers: getHeaders() })
            .then((resposta) => {
                loading(false);
                alerta.value = {
                    title: strings.sucesso,
                    message: strings.examePriorizado,
                    type: 'success',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = { ...alerta.value, open: false };
                                fecharPriorizar();
                                listar.value = true;
                                if (callback) {
                                    callback();
                                }
                            }

                        }
                    ],
                    onClose: () => {
                        alerta.value = { ...alerta.value, open: false };
                        fecharPriorizar();
                        listar.value = true;
                    }
                };
            })
            .catch(err => {
                loading(false);
                console.log(err);
                alerta.value = {
                    title: strings.erro,
                    message: strings.erroPriorizarExame,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => alerta.value = { ...alerta.value, open: false }

                        }
                    ],
                    onClose: () => alerta.value = { ...alerta.value, open: false }
                };
            });
    }

    const loading = (open) => {
        carregando.value = { open: open, text: strings.priorizandoExame };
    }

    return (
        <>
            <SttModal
                title={strings.priorizar}
                open={priorizar}
                outModalClose={fecharPriorizar}
                iconClose={fecharPriorizar}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttAutocomplete
                                    inputprops={{
                                        label: strings.motivo,
                                        required: true
                                    }}
                                    getOptionLabel={option => option.descricao}
                                    options={motivosPriorizacao.value}
                                    value={motivo.value}
                                    onChange={selecionarMotivo}
                                />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton
                            variant="contained"
                            color="primary"
                            disabled={!motivo.value}
                            onClick={() => {
                                alerta.value = {
                                    title: strings.atencao,
                                    message: strings.confirmarPriorizarExame,
                                    type: 'confirmation',
                                    open: true,
                                    options: [
                                        {
                                            title: strings.sim,
                                            onClick: () => {
                                                priorizarExame();
                                                alerta.value = { ...alerta.value, open: false };
                                            }
                                        },
                                        {
                                            title: strings.nao,
                                            onClick: () => alerta.value = { ...alerta.value, open: false }
                                        }
                                    ],
                                    onClose: () => alerta.value = { ...alerta.value, open: false }
                                };
                            }}
                        >
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharPriorizar}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
        </>
    );
}

export default Priorizar;
