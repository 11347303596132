import * as yup from 'yup'
import { MEDICO_SOLICITANTE, INSTITUICAO_SOLICITANTE } from './fieldNames'


export default (strings) => {
    return yup.object().shape({
        [MEDICO_SOLICITANTE]: yup.object().shape({
            id: yup.number(),
            nome: yup.string(),
            cpf: yup.string()
        }).nullable().required(strings.campoObrigatorio),
        [INSTITUICAO_SOLICITANTE]: yup.object().shape({
            id: yup.number(),
            nome: yup.string()
        }).nullable().required(strings.campoObrigatorio)
    });
}