import React, { useContext } from 'react';
import HttpStatus from 'http-status-codes';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { getHeaders } from '../../request';
import axios from 'axios';
import usuario from '../../signals/usuario';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal } from '@preact/signals-react';
import alerta from '../../signals/alerta';

import { 
    SttModal,
    SttButton,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';

const ConfirmarLaudo = (props) => {
    const { html, callbackConfirmar, callbackCancelar, open, idExame } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { values, resetForm } = useFormikContext();

    const confirmando = useSignal(false);
    const assinando = useSignal(false);

    useSignals();

    const handleCloseAlerta = () => {
        alerta.value = { ...alerta.value, open: false };
    }

    const confirmar = () => {
        confirmando.value = true;
        const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;
        const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
        axios
            .post(`${ESTOMATO_API_BASE_URL}/laudo/confirmar`, values, { headers: getHeaders() })
            .then((response) => {
                resetForm();

                if (usuario.cpf && usuario.idufsc) {
                    const urlAuthAssinaturaDigital = `${global.gConfig.url_base_assinatura_digital_ufsc}?cpfAssinador=${usuario.cpf}`;
                    var win = window.open(urlAuthAssinaturaDigital, "_blank", "height=800, width=1000;");
                    var timer = setInterval(() => {
                        if(win.closed) {
                            clearInterval(timer);
                            assinando.value = true;
                            axios
                                .post(`${EXAME_API_BASE_URL}/assinatura-laudo`, { exame: idExame }, { headers: getHeaders() })
                                .then((res) => {
                                    alerta.value = {
                                        title: strings.sucesso,
                                        message: strings.laudoAssinado,
                                        type: 'success',
                                        open: true,
                                        options: [
                                            {
                                                title: strings.ok,
                                                onClick: callbackConfirmar
                    
                                            }
                                        ],
                                        onClose: callbackConfirmar
                                    };
                                })
                                .catch(err => {
                                    alerta.value = {
                                        title: strings.erro,
                                        message: strings.erroAssinatura,
                                        type: 'error',
                                        open: true,
                                        options: [
                                            {
                                                title: strings.ok,
                                                onClick: callbackConfirmar
                    
                                            }
                                        ],
                                        onClose: callbackConfirmar
                                    };
                                })
                                .finally(() => {
                                    assinando.value = false;
                                });
                        }
                    }, 1500);
                } else {
                    callbackConfirmar();
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }
                }

                alerta.value = {
                    title: strings.erro,
                    message: msg,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: handleCloseAlerta

                        }
                    ],
                    onClose: handleCloseAlerta
                };
            })
            .finally(() => {
                confirmando.value = false;
            });
    }
    
    return (
        <>
            <SttModal
                title={strings.resumoLaudo}
                open={open}
                outModalClose={callbackCancelar}
                iconClose={callbackCancelar}
                maxWidth="lg"
                fullWidth={true}
                children={
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" disabled={confirmando.value} onClick={confirmar}>
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={callbackCancelar}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <SttLoading
                open={confirmando.value}
                text={strings.confirmando}
            />
            <SttLoading
                open={assinando.value}
                text={strings.assinando}
            />
        </>
    );
}

ConfirmarLaudo.propTypes = {
    html: PropTypes.string.isRequired,
    idExame: PropTypes.number.isRequired,
    callbackConfirmar: PropTypes.func.isRequired, 
    callbackCancelar: PropTypes.func.isRequired, 
    open: PropTypes.bool.isRequired
};

export default ConfirmarLaudo;