import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import { temPermissaoRBAC } from '../../secutity/acl';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import alerta from '../../signals/alerta';
import usuario from '../../signals/usuario';
import carregando from '../../signals/carregando';
import { listar } from '../../signals/exame';
import {
    SttGrid,  
    SttButton, 
    SttModal, 
    SttText, 
    SttAutocomplete, 
    SttInput, 
    SttTranslateHook 
} from '@stt-componentes/core';
import { PERMISSOES, MODALIDADE } from '../../common/Constants';

const useStyles = makeStyles(theme => ({
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));

const Invalidacao = (props) => {
    const { invalidar, setInvalidar, idExame, idInstituicao, idRede, callback } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
    const classes = useStyles();

    const motivos = useSignal([]);
    const motivo = useSignal(null);
    const observacao = useSignal('');
    const formValido = useSignal(false);

    useSignals();

    useSignalEffect(() => {
        if (!motivos.value.length) {
            let params = { modalidade: MODALIDADE.SIGLA };
            if (idRede) {
                params.rede = idRede;
            }
            axios
                .get(`${EXAME_API_BASE_URL}/motivo-invalidacao`, { params, headers: getHeaders() })
                .then((response) => {
                    // Se não for médico, a seleção do motivo não é obrigatória pelo usuário
                    // É atribuído um motivo padrão
                    if (!temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME)) {
                        const motivosTecnico = response.data.filter(m => m.motivo_padrao_tecnico);
                        motivos.value = motivosTecnico;
                        if (motivosTecnico.length) {
                            handleChangeMotivo(null, motivosTecnico[0]);
                        }
                        return;
                    }
                    motivos.value = response.data;
                })
                .catch(err => console.log(err));
        }

        // Técnicos e administradores não podem laudar e só precisam da observação textual
        if (!temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME)) {
            if (observacao.value.trim()) {
                formValido.value = true;
            } else {
                formValido.value = false;
            }
        } else {
            // Laudadores precisam selecionar o motivo
            if (motivo.value) {
                formValido.value = true;
            } else {
                formValido.value = false;
            }
        }
    });

    const fecharInvalidacao = () => {
        motivo.value = null;
        setInvalidar(false);
    }

    const handleChangeMotivo = (event, novoMotivo) => {
        motivo.value = novoMotivo;
    }

    const handleChangeObservacao = (event) => {
        observacao.value = event.target.value;
    }

    const loading = (open) => {
        carregando.value = { open: open, text: strings.invalidandoExame };
    }

    const invalidarExame = () => {
        loading(true);
        const payload = { 
            idExame, 
            instituicao: idInstituicao, 
            motivo: motivo.value.id, 
            observacao: observacao.value.trim() 
        };
        axios
            .post(`${EXAME_API_BASE_URL}/invalidar`, payload, { headers: getHeaders() })
            .then(() => {
                loading(false);
                alerta.value = {
                    title: strings.sucesso,
                    message: strings.exameInvalidado,
                    type: 'success',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = { ...alerta.value, open: false };
                                fecharInvalidacao();
                                listar.value = true;
                                if (callback) {
                                    callback();
                                }
                            }

                        }
                    ],
                    onClose: () => {
                        alerta.value = { ...alerta.value, open: false };
                        fecharInvalidacao();
                        listar.value = true;
                    }
                };
            })
            .catch(err => {
                loading(false);
                console.log(err);
                alerta.value = {
                    title: strings.erro,
                    message: strings.erroInvalidarExame,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => alerta.value = { ...alerta.value, open: false }

                        }
                    ],
                    onClose: () => alerta.value = { ...alerta.value, open: false }
                };
            });
    }

    return (
        <>
            <SttModal
                title={strings.invalidar}
                open={invalidar}
                outModalClose={fecharInvalidacao}
                iconClose={fecharInvalidacao}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                {
                                    temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME) && 
                                    <SttText variant="body1">{strings.textoInvalidar}</SttText> ||
                                    <SttText variant="body1">{strings.textoInvalidarExameObs}</SttText>
                                }
                            </SttGrid>
                            {
                                temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME) && 
                                <SttGrid item xs={12}>
                                    <SttAutocomplete
                                        inputprops={{
                                            label: strings.motivo,
                                            required: true
                                        }}
                                        getOptionLabel={option => option.descricao}
                                        options={motivos.value}
                                        value={motivo.value}
                                        onChange={handleChangeMotivo}
                                    />
                                </SttGrid>
                            }
                            {
                                !temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME) && 
                                <SttGrid item xs={12}>
                                    <SttInput 
                                        value={observacao.value}
                                        label={strings.observacao}
                                        required
                                        onChange={handleChangeObservacao}
                                        multiline
                                        minRows={3}
                                    />
                                </SttGrid>
                            }
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton 
                            variant="contained" 
                            color="primary" 
                            disabled={!formValido.value} 
                            onClick={() => {
                                alerta.value = {
                                    title: strings.atencao,
                                    message: strings.confirmarInvalidarExame,
                                    type: 'confirmation',
                                    open: true,
                                    options: [
                                        {
                                            title: strings.sim,
                                            onClick: () => {
                                                invalidarExame();
                                                alerta.value = { ...alerta.value, open: false }
                                            }
                                        },
                                        {
                                            title: strings.nao,
                                            onClick: () => alerta.value = { ...alerta.value, open: false }
                                        }
                                    ],
                                    onClose: () => alerta.value = { ...alerta.value, open: false }
                                };
                            }}
                        >
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharInvalidacao}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
        </>
    );
};

Invalidacao.propTypes = {
    idExame: PropTypes.number.isRequired,
    invalidar: PropTypes.bool.isRequired, 
    setInvalidar: PropTypes.func.isRequired, 
    idRede: PropTypes.number
};

export default Invalidacao;