import React, {} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import GridLaudosGraves from '../../componentes/laudos-graves';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(3)
    }
}));

const LaudosGraves = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <GridLaudosGraves />
        </div>
    );
};

export default LaudosGraves;
