import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTextItem,
    SttHeading,
    SttDivider,
    SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

const LesoesV1 = (props) => {
    const { exame } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    
    return (
        exame.value.lesoes?.map((lesao, idx) => (
            <div key={idx}>
                <SttHeading variant="h4" color="primary" align="left">{`${strings.lesao} ${idx+1}`}</SttHeading>
                <SttTextItem title={strings.lesaoFundamental} content={`${lesao.lesao_fundamental} ${lesao.complemento_lesao_fundamental ? ' - ' + lesao.complemento_lesao_fundamental : ''}`} />
                <SttTextItem title={strings.estruturaAfetada} content={lesao.estrutura_afetada} />
                {lesao.localizacao_lesao && <SttTextItem title={strings.localizacaoLesao} content={lesao.localizacao_lesao} />}
                {lesao.altura_lesao_fundamental && <SttTextItem title={strings.altura} content={lesao.altura_lesao_fundamental} />}
                {lesao.largura_lesao_fundamental && <SttTextItem title={strings.largura} content={lesao.largura_lesao_fundamental} />}
                <SttTextItem title={strings.cor} content={lesao.cor_lesao_fundamental} />
                <SttTextItem title={strings.superficie} content={`${lesao.superficie} ${lesao.observacao_superficie ? ' - ' + lesao.observacao_superficie : ''}`} />
                <SttTextItem title={strings.palpacao} content={lesao.palpacao} />
                <SttTextItem title={strings.tempoEvolucao} content={lesao.tempo_evolucao} />
                {lesao.tipo_crescimento && <SttTextItem title={strings.tipoCrescimento} content={lesao.tipo_crescimento} />}
                {
                    !lesao.assintomatica &&
                    <>
                        <SttTextItem title={strings.localizacaoDor} content={lesao.localizacao_dor} />
                        <SttTextItem title={strings.espontaneidadeDor} content={lesao.espontaneidade_dor} />
                        <SttTextItem title={strings.duracaoDor} content={lesao.duracao_dor} />
                        <SttTextItem title={strings.intensidadeDor} content={lesao.intensidade_dor} />
                        <SttTextItem title={strings.tipoDor} content={lesao.tipo_dor} />
                        <SttTextItem title={strings.queimacao} content={lesao.queimacao} />
                        <SttTextItem title={strings.alteracaoSensibilidade} content={lesao.sensibilidade} />
                    </> ||
                    <SttTextItem title={strings.assintomatica} content={lesao.assintomatica_hr} />
                }
                <SttTextItem title={strings.lesaoSaiRaspagem} content={lesao.lesao_sai_raspagem} />
                <SttDivider className={classes.divider} />
            </div>
        ))
    )

}

export default LesoesV1;