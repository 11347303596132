import { 
    ID_SOLICITACAO, 
    EQUIPAMENTO, 
    OBSERVACAO,
    ARQUIVO, 
    LESAO, 
    DATA_AQUISICAO, 
    LESAO_FUNDAMENTAL
} from './fieldNames';

export const initialValues = (solicitacao) => {

    let state = { [ID_SOLICITACAO]: solicitacao.id, [EQUIPAMENTO]: null, [OBSERVACAO]: '' };
    solicitacao.lesoes.forEach(lesao => {
        state[`${LESAO}_${lesao.numero}`] = [
            {
                [LESAO_FUNDAMENTAL]: lesao.lesaoFundamental,
                [ARQUIVO]: null,
                [DATA_AQUISICAO]: null
            }
        ];
    });
    return state;
}