import * as yup from 'yup'

import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';

import solicitanteSchema from '../../componentes/solicitacao/solicitante/validationSchema';
import lesoeschema from '../../componentes/solicitacao/lesoes/validationSchema';
import habitosSchema from '../../componentes/solicitacao/habitos/form/validationSchema';
import tratamentoPrevioSchema from '../../componentes/solicitacao/tratamento-previo/form/validationSchema';
import observacoesSchema from '../../componentes/solicitacao/observacoes/form/validationSchema';
import pacienteSchema from '@stt-componentes/paciente/dist/lib/form/validationSchema';

const schema = (strings, campos) => {

    const paciente = yup.object().shape({ [PACIENTE]: pacienteSchema(strings, campos) });
    const solicitante = solicitanteSchema(strings);
    const lesoes = lesoeschema(strings);
    const habitos = habitosSchema(strings);
    const tratamentoPrevio = tratamentoPrevioSchema(strings);
    const observacoes = observacoesSchema(strings);
    const schema =  yup.object().shape({});
    
    return schema
        .concat(habitos)
        .concat(tratamentoPrevio)
        .concat(observacoes)
        .concat(lesoes)
        .concat(solicitante)
        .concat(paciente);
};

export default schema;
