import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3)
    },
    card: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    table: {
        marginBottom: theme.spacing(3)
    },
    
    container: {
        paddingTop: theme.spacing(3)
    },
    
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    botao: {
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center'
    }

}));

export default useStyles;