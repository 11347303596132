import React, { useContext } from 'react';
import Anexos from './form-anexos';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttModal,
    SttButton,
    SttTranslateHook,
    SttHidden,
    SttStepper,
    SttMobileStepper
} from '@stt-componentes/core';
import DadosGerais from './form-dados-gerais';
import { useFormikContext } from 'formik';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import { solicitacao } from '../../../signals/envio-imagens';

const useStyles = makeStyles(theme => ({
    mobileStepper: {
        marginBottom: theme.spacing(1)
    }
}));

const WrapperForm = (props) => {
    const { 
        confirmarFecharModal, 
        open, 
        enviarFn
    } = props;
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { touched, setTouched, validateForm } = useFormikContext();
    
    const steps = useSignal([]);
    const stepAtual = useSignal(0);
    const selecionarAnexos = useSignal(false);
    
    useSignals();

    const proximoStep = () => {
        stepAtual.value = stepAtual + 1;
    }
    
    const stepAnterior = () => {
        stepAtual.value = stepAtual - 1;
    }
    
    useSignalEffect(() => {
        let passos = [ strings.dadosGerais ];
        if (!solicitacao.value.dados.lesoes.length) {
            passos.push(strings.imagens);
        } else {
            solicitacao.value.dados.lesoes.map(lesao => {
                if (lesao.lesaoFundamental) {
                    passos.push(`${strings.lesao} ${lesao.numero} ${lesao.lesaoFundamental} ${lesao.estruturaAfetada && ` - ${lesao.estruturaAfetada}`}`)
                } else {
                    passos.push(strings.imagens);
                }
            });
        }
        steps.value = passos;
        
        if (stepAtual.value === 0) {
            selecionarAnexos.value = false;
        } else {
            selecionarAnexos.value = true;
        }
    });

    return (
        <SttModal
            title={strings.envioImagens}
            open={open}
            outModalClose={confirmarFecharModal}
            iconClose={confirmarFecharModal}
            maxWidth="md"
            fullWidth={true}
            children={
                <>
                    <SttHidden only={['xs', 'sm']}>
                        <SttStepper steps={steps.value.map(step => step)} activeStep={stepAtual.value} />
                        {
                            selecionarAnexos.value &&
                            <Anexos lesao={solicitacao.value.dados.lesoes[stepAtual.value-1]} /> ||
                            <DadosGerais />
                        }
                    </SttHidden>
                    <SttHidden mdUp>
                        {
                            selecionarAnexos.value &&
                            <Anexos lesao={solicitacao.value.dados.lesoes[stepAtual.value-1]} /> ||
                            <DadosGerais />
                        }
                        <SttMobileStepper
                            className={classes.mobileStepper}
                            steps={steps.value.length}
                            activeStep={stepAtual}
                        />
                    </SttHidden>
                </>
            }
            footer={
                <>
                    {
                        stepAtual > 0 &&
                        <SttButton
                            variant="outlined"
                            color="primary"
                            onClick={stepAnterior}>
                            {strings.anterior}
                        </SttButton>
                    }
                    {
                        stepAtual.value < steps.value.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={() => validateForm().then((erros) => {
                                console.log(erros);
                                if (Object.keys(erros).length) {
                                    setTouched({ ...touched, ...erros });
                                    return;
                                }
                                proximoStep();
                            })}>
                            {strings.proximo}
                        </SttButton>
                    }
                    {
                        stepAtual.value === steps.value.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={enviarFn}>
                            {strings.enviarImagens}
                        </SttButton>
                    }
                    <SttButton
                        variant="outlined"
                        color="secondary"
                        onClick={confirmarFecharModal}
                    >
                        {strings.cancelar}
                    </SttButton>
                </>
            }
        />
    );
}

export default WrapperForm;