import * as yup from 'yup'
import { 
    ID_EXAME,
    ID_LAUDO_EMISSAO,
    TIMESTAMP_INICIO,
    DESCRICAO_ESTUDO,
    CID_10,
    DECS,
    CLASSIFICACAO_RISCO,
    HIPOTESE_DIAGNOSTICA,
    COMPLEMENTO_HIPOTESE_DIAGNOSTICA,
    ORIENTACOES,
    CONCLUSAO,
    ID_INSTITUICAO
} from './fieldNames';

import { 
    COMPLEMENTO_LESAO_FUNDAMENTAL, 
    LESAO_FUNDAMENTAL, 
    LESOES, 
    LOCALIZACAO, 
    OUTRA_COR, 
    COR, 
    SUPERFICIE,
    ESTRUTURA_AFETADA,
    LADO_AFETADO,
    REGIAO_AFETADA,
    ARCADA_AFETADA,
    OCORRENCIA_LESAO
} from '../solicitacao/lesoes/fieldNames';
import { VERSAO } from '../../common/Constants';

export default (strings, versao) => {
    let schemaLesao = yup
        .object()
        .shape({
            [LESAO_FUNDAMENTAL]: yup.number().nullable().required(strings.campoObrigatorio),
            [COMPLEMENTO_LESAO_FUNDAMENTAL]: yup.string().trim().nullable().when(LESAO_FUNDAMENTAL, {
                is: val => val === 14, // TODO ver pelo atributo "complemento" da opção selecionada
                then: yup.string().trim().required(strings.campoObrigatorio)
            }),
            [LOCALIZACAO]: yup.number().nullable().required(strings.campoObrigatorio),
            [COR]: yup.number().nullable().required(strings.campoObrigatorio),
            [OUTRA_COR]: yup.string().trim().nullable().when(COR, {
                is: val => val === 9, // TODO ver pelo atributo "complemento" da opção selecionada
                then: yup.string().trim().required(strings.campoObrigatorio)
            }),
            [SUPERFICIE]: yup.number().nullable().required(strings.campoObrigatorio),
            [CLASSIFICACAO_RISCO]: yup.string().nullable().required(strings.campoObrigatorio),
            [HIPOTESE_DIAGNOSTICA]: yup.object().nullable().required(strings.campoObrigatorio),
            [COMPLEMENTO_HIPOTESE_DIAGNOSTICA]: yup.string().trim().nullable().when(HIPOTESE_DIAGNOSTICA, {
                is: val => val?.exige_complemento,
                then: yup.string().trim().required(strings.campoObrigatorio)
            }),
            [ORIENTACOES]: yup.string().trim().nullable(),
            [CONCLUSAO]: yup.string().trim().nullable(),
        })
        .required(strings.campoObrigatorio);

    if (versao === VERSAO.ANTIGA) {
        schemaLesao = schemaLesao.concat(
            yup.object().shape({
                [ESTRUTURA_AFETADA]: yup.array().of(yup.string().nullable()).min(1, strings.campoObrigatorio).nullable().required(strings.campoObrigatorio),
                [LADO_AFETADO]: yup.string().nullable().required(strings.campoObrigatorio),
                [REGIAO_AFETADA]: yup.array().of(yup.number().nullable()).min(1, strings.campoObrigatorio).nullable().required(strings.campoObrigatorio),
                [ARCADA_AFETADA]: yup.string().nullable().required(strings.campoObrigatorio),
                [OCORRENCIA_LESAO]: yup.string().nullable().required(strings.campoObrigatorio)
            })
        );
    }
    
    return yup.object().shape({
        [ID_EXAME]: yup.number().required(),
        [ID_INSTITUICAO]: yup.number().required(),
        [ID_LAUDO_EMISSAO]: yup.number().required(),
        [DESCRICAO_ESTUDO]: yup.string().required(),
        [TIMESTAMP_INICIO]: yup.number().required(),
        [CID_10]: yup.array().nullable(),
        [DECS]: yup.array().nullable(),
        [LESOES]: yup.array().of(schemaLesao).min(1)
    });
};