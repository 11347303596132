import { signal } from "@preact/signals-react";

import { estadoInicialForm } from '../../componentes/envio-imagens/grid-solicitacoes/estadoInicialForm';

export const filtros = signal(estadoInicialForm);
export const listar = signal(false);

export const solicitacao = signal({
    dados: null,
    set: (dados) => {
        const lesoes = [];
        if (dados.lesoes.length) {
            for (const [i, lesao] of dados.lesoes.entries()) {
                lesoes.push({
                    numero: i + 1,
                    lesaoFundamental: lesao.lesao_fundamental,
                    estruturaAfetada: lesao.estrutura_afetada || '',
                    arquivos: [{}]
                });
            }
        } else {
            lesoes.push({
                numero: 1,
                lesaoFundamental: '',
                estruturaAfetada: '',
                arquivos: [{}]
            });
        }
        solicitacao.value = { 
            ...solicitacao.value,
            dados: {
                ...dados,
                lesoes: lesoes
            }
        };

    }
});