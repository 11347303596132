import React, { useContext } from 'react';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import {
    SttGrid,
    SttInput,
    SttTranslateHook,
    SttAutocomplete
} from '@stt-componentes/core';
import {
    EQUIPAMENTO,
    OBSERVACAO
} from './fieldNames';
import { Field, FastField, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { temPerfilRBAC } from '../../../secutity/acl';
import { PERFIL, MODALIDADE } from '../../../common/Constants';
import usuario from '../../../signals/usuario';

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 0,
        marginTop: theme.spacing(1)
    }
}));

const DadosGerais = () => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { setFieldValue } = useFormikContext();

    const equipamentos = useSignal([]);

    useSignals();

    useSignalEffect(() => {
        let instituicoes;
        if (!temPerfilRBAC(usuario, PERFIL.MEDICO_SOLICITANTE)) {
            instituicoes = usuario.value.habilitacao.solicitacaoServico.filter(
                inst => inst.modalidades?.some(
                    modalidade => modalidade.sigla === MODALIDADE.SIGLA
                )
            ).map(inst => inst.id);
        } else {
            instituicoes = usuario.value.habilitacao.vinculo.map(inst => inst.id);
        }

        if (instituicoes.length) {
            const stringInsts = instituicoes.join();
            const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/equipamento?modalidade=${MODALIDADE.SIGLA}&instituicao=${stringInsts}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        equipamentos.value = response.data;
                        if (equipamentos.value.length === 1) {
                            setFieldValue(EQUIPAMENTO, equipamentos.value[0]);
                        }
                    }
                })
                .catch(err => console.log(err));
        }

    });

    return (
        <>
            <SttGrid container spacing={2} className={classes.container}>
                <SttGrid item xs={12} md={6}>
                    <Field name={EQUIPAMENTO}>
                        {({
                            field: { name, value, onBlur, ...other },
                            meta,
                        }) => (
                            <SttAutocomplete 
                                inputprops={{
                                    name: name,
                                    label: strings.equipamento,
                                    required: true,
                                    error: meta.touched && meta.error ? meta.error : undefined
                                }}
                                {...other}
                                getOptionLabel={option => option && option.id_humano || ''}
                                options={equipamentos.value}
                                value={value}
                                onBlur={onBlur}
                                onChange={(e, item) => setFieldValue(EQUIPAMENTO, item || null)}
                            />
                        )}
                    </Field>
                </SttGrid>
                {
                    !temPerfilRBAC(usuario, PERFIL.MEDICO_SOLICITANTE) &&
                    <SttGrid item xs={12} sm={12}>
                        <FastField name={OBSERVACAO}>
                            {({
                                field,
                                meta
                            }) => (
                                <SttInput
                                    multiline
                                    minRows={5}
                                    {...field}
                                    label={strings.observacoes}
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                />
                            )}
                        </FastField>
                    </SttGrid>
                }
            </SttGrid>
        </>
    );
}

export default DadosGerais;