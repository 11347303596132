import React, { useRef, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Field, FastField } from 'formik';
import axios, { HttpStatusCode } from 'axios';
import { getHeaders } from '../../../request';
import { useMoment } from '../../../hooks';
import validationSchema from './validationSchema';
import { temPerfilRBAC } from '../../../secutity/acl';
import PropTypes from 'prop-types';
import { estadoInicialForm } from './estadoInicialForm';
import { PERFIL } from '../../../common/Constants';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import { filtros, listar } from '../../../signals/envio-imagens';
import alerta from '../../../signals/alerta';
import usuario from '../../../signals/usuario';
import {
    SttGrid,
    SttDatePicker,
    SttInput,
    SttAutocomplete,
    SttButton, 
    SttTranslateHook
} from '@stt-componentes/core';
import { 
    DATA_DE,
    DATA_ATE,
    NOME_SOLICITANTE,
    NOME_PACIENTE,
    UF,
    MUNICIPIO
} from './fieldNames';

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const Form = (props) => {
    const { 
        callbackBusca, 
        buscaEmAndamento, 
        page, 
        count, 
        orderBy, 
        order, 
        resetPageOrder
    } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const moment = useMoment();
    const formRef = useRef()
    const classes = useStyles();
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
    const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;

    const ufs = useSignal([]);
    const municipios = useSignal([]);

    useSignals();

    useSignalEffect(() => {
        if (temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) || temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL)) {
            let url = `${UTILITARIOS_API_BASE_URL}/localizacao/estado`;
            if (temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL)) {
                const perfil = usuario.perfisRBAC.filter(p => p.identificador === PERFIL.ADMINISTRADOR_ESTADUAL);
                const estados = perfil[0].estados;
                const idsUf = estados.map(e => e.id);
                url = `${url}?ids=${idsUf.join(',')}`;
            }
            axios
                .get(url)
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        ufs.value = itens;
                    }
                })
                .catch(err => console.log(err));
        }

        if (listar.value && formRef.current) {
            formRef.current.handleSubmit();
        }
    });

    const handleChangeUf = (uf) => {
        if (uf) {
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${uf.id}`)
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        municipios.value = itens;
                    }
                })
                .catch(err => console.log(err));
        }
    }

    return (
        <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={filtros.value}
            validationSchema={schema}
            onSubmit={(data, { setSubmitting }) => {
                filtros.value = data;

                setSubmitting(true);

                buscaEmAndamento.value = true;

                let params = {
                    start: page * count,
                    count: count
                };
                if (data.de) {
                    params.de = moment(data.de).format('YYYY-MM-DD');
                }
                if (data.ate) {
                    params.ate = moment(data.ate).format('YYYY-MM-DD');
                }
                if (data.solicitante) {
                    params.solicitante = data.solicitante.trim();   
                }
                if (data.paciente) {
                    params.paciente = data.paciente.trim();
                }
                if (data.uf) {
                    params.uf = data.uf.id;
                }
                if (data.municipio) {
                    params.municipio = data.municipio.id;
                }
                if (orderBy && order) {
                    params.sort = orderBy;
                    params.direction = order;
                }

                axios
                    .get(`${ESTOMATO_API_BASE_URL}/solicitacao`, { params, headers: getHeaders() })
                    .then((response) => {
                        const dados = response.data.data;
                        callbackBusca(dados);
                    })
                    .catch(err => {
                        console.error(err);
                        if (err.response) {
                            const { response: { status } } = err;
                            if (status === HttpStatusCode.NotFound) {
                                callbackBusca({ totalRegistros: 0, itens: [] });
                                console.log('aquiiiii');
                                return;
                            }
                        }
                        callbackBusca(null);
                        alerta.value = {
                            title: strings.erro,
                            message: strings.erroGenerico,
                            type: 'error',
                            open: true,
                            options: [
                                {
                                    title: strings.ok,
                                    onClick: () => alerta.value = { ...alerta.value, open: false }
        
                                }
                            ],
                            onClose: () => alerta.value = { ...alerta.value, open: false }
                        };
                    })
                    .finally(() => {
                        setSubmitting(false);
                        buscaEmAndamento.value = false;
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12} sm={12} md={6} xl={4}>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12} sm={6}>
                                            <FastField name={DATA_DE}>
                                                {({
                                                    field: { name, value, },
                                                    form: { setFieldValue, setFieldError, setFieldTouched },
                                                    meta
                                                }) => {
                                                    return (
                                                        <SttDatePicker
                                                            label={strings.dataInicialSolicitacao} 
                                                            inputprops={{
                                                                name: name
                                                            }}
                                                            disableFuture
                                                            maxDate={new Date()}
                                                            error={meta.touched && meta.error ? true : false}
                                                            onError={error => {
                                                                setFieldError(DATA_DE, meta.error || error);
                                                            }}
                                                            value={value}
                                                            onBlur={() => {
                                                                setFieldTouched(DATA_DE, true);
                                                            }}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            onChange={date => setFieldValue(DATA_DE, date, true)}
                                                            onClose={() => setFieldTouched(DATA_DE, true)}
                                                        />
                                                    );
                                                }}
                                            </FastField>
                                        </SttGrid>
                                        <SttGrid item xs={12} sm={6}>
                                            <FastField name={DATA_ATE}>
                                                {({
                                                    field: { name, value },
                                                    form: { setFieldValue, setFieldError, setFieldTouched },
                                                    meta
                                                }) => {
                                                    return (
                                                        <SttDatePicker
                                                            label={strings.dataFinalSolicitacao} 
                                                            inputprops={{
                                                                name: name
                                                            }}
                                                            disableFuture
                                                            maxDate={new Date()}
                                                            error={meta.touched && meta.error ? true : false}
                                                            onError={error => {
                                                                setFieldError(DATA_ATE, meta.error || error);
                                                            }}
                                                            value={value}
                                                            onBlur={() => {
                                                                setFieldTouched(DATA_ATE, true);
                                                            }}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            onChange={date => setFieldValue(DATA_ATE, date, true)}
                                                            onClose={() => setFieldTouched(DATA_ATE, true)}
                                                        />
                                                    );
                                                }}
                                            </FastField>
                                        </SttGrid>
                                    </SttGrid>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                    <FastField name={NOME_SOLICITANTE}>
                                        {({
                                            field
                                        }) => (
                                            <SttInput 
                                                {...field}
                                                label={strings.solicitante}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                    <FastField name={NOME_PACIENTE}>
                                        {({
                                            field
                                        }) => (
                                            <SttInput 
                                                {...field}
                                                label={strings.paciente}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                {
                                    (temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) || temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL)) &&
                                    <>
                                        <SttGrid item xs={12} sm={3} md={2} lg={1}>
                                            <Field name={UF}>
                                                {({
                                                    field: { name, value, onBlur },
                                                    form: { setFieldValue }
                                                }) => (
                                                    <SttAutocomplete 
                                                        inputprops={{
                                                            name: name,
                                                            label: strings.uf
                                                        }}
                                                        getOptionLabel={option => option && option.sigla || ''}
                                                        getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                        options={ufs.value}
                                                        value={value}
                                                        onBlur={onBlur}
                                                        onChange={(e, item) => {
                                                            const uf = item || null;
                                                            setFieldValue(UF, uf);
                                                            setFieldValue(MUNICIPIO, null);
                                                            handleChangeUf(uf)
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>
                                        <SttGrid item xs={12} sm={9} md={4} lg={4} xl={3}>
                                            <Field name={MUNICIPIO}>
                                                {({
                                                    field: { name, value, onBlur },
                                                    form: { setFieldValue }
                                                }) => (
                                                    <SttAutocomplete 
                                                        inputprops={{
                                                            name: name,
                                                            label: strings.municipio
                                                        }}
                                                        getOptionLabel={option => option && option.nome || ''}
                                                        getOptionSelected={(option, v) => option && v && option.id === v.id}
                                                        options={municipios.value}
                                                        value={value}
                                                        onBlur={onBlur}
                                                        onChange={(e, item) => {
                                                            setFieldValue(MUNICIPIO, item || null);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>
                                    </>
                                }
                            </SttGrid>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12} className={classes.buttonWrapper}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        nomarginleft="true"
                                        onClick={() => {
                                            resetPageOrder();
                                        }}
                                    >
                                        {strings.pesquisar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            filtros.value = estadoInicialForm;
                                            resetForm({ values: estadoInicialForm });
                                            callbackBusca(null);
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
};

Form.propTypes = {
    callbackBusca: PropTypes.func.isRequired,
    buscaEmAndamento: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired, 
    count: PropTypes.number.isRequired, 
    orderBy: PropTypes.string, 
    order: PropTypes.string.isRequired, 
    resetPageOrder: PropTypes.func.isRequired
};

export default Form;