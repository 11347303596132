import React, { useContext, Suspense, lazy } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { PERMISSOES, VERSAO } from '../../../common/Constants';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import usuario from '../../../signals/usuario';
import { solicitacao as dadosEnvioImagens } from '../../../signals/envio-imagens';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress, 
    SttTranslateHook
} from '@stt-componentes/core';

import EnvioImagens from '../index';
import { temPermissaoRBAC } from '../../../secutity/acl';
const Boca = lazy(() => import('../../boca'));

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    conteinerLesao: {
        marginBottom: theme.spacing(1)
    }
}));

const Detalhes = (props) => {
    const { id, voltar } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    
    const solicitacao = useSignal(null);
    const enviarImagens = useSignal(false);

    useSignals();

    useSignalEffect(() => {
        
        const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;

        if (id) {
            axios
                .get(`${ESTOMATO_API_BASE_URL}/solicitacao/${id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { data } = response.data;
                        solicitacao.value = data;
                        dadosEnvioImagens.value.set(data);
                    }
                })
                .catch(err => console.log(err));
        }
    });

    const callbackEnvioImagens = () => {
        voltar(true);
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                !solicitacao.value 
                ?
                <div className={classes.carregando}>
                    <SttCircularProgress color="primary" />
                </div>
                :
                <>
                    <SttHeading variant="h1" color="primary" align="center">{strings.detalhesSolicitacao}</SttHeading>

                    <SttExpansionPanel
                        title={strings.dadosExame}
                        children={
                            <div>
                                <SttTextItem key="2" title={strings.dataSolicitacao} content={`${solicitacao.value.data_solicitacao_formatada} ${global.gConfig.sufixo_data_hora}`} />
                                <SttTextItem key="3" title={strings.solicitante} content={solicitacao.value.nome_solicitante} />
                                <SttTextItem key="4" title={strings.cidadeProcedencia} content={solicitacao.value.cidade_procedencia} />
                            </div>
                        }
                    />

                    <SttExpansionPanel
                        title={strings.dadosPaciente}
                        children={
                            <div>
                                <SttTextItem key="5" title={strings.nome} content={solicitacao.value.nome_paciente} />
                                <SttTextItem key="6" title={strings.dataNascimento} content={solicitacao.value.data_nascimento_paciente} />
                                <SttTextItem key="7" title={strings.idade} content={`${solicitacao.value.idade_paciente} anos`} />
                                <SttTextItem key="8" title={strings.genero} content={solicitacao.value.sexo_paciente} />
                                <SttTextItem key="9" title={strings.cns} content={solicitacao.value.cartao_sus} />
                                {solicitacao.value.peso_paciente && <SttTextItem key="10" title={strings.peso} content={solicitacao.value.peso_paciente} />}
                                {solicitacao.value.altura_paciente && <SttTextItem key="11" title={strings.altura} content={solicitacao.value.altura_paciente} />}
                            </div>
                        }
                    />
                    
                    <SttExpansionPanel
                        title={strings.indicacaoClinica}
                        children={
                            <div>
                                <SttTextItem key="13" title={strings.queixaPrincipal} content={solicitacao.value.queixa_principal} />
                            </div>
                        }
                    />
                    
                    {
                        solicitacao.value.versao === VERSAO.NOVA &&
                        solicitacao.value.lesoes.length > 0 &&
                        <SttExpansionPanel
                            title={strings.lesoes}
                            children={
                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                    <Boca
                                        points={solicitacao.value.lesoes} 
                                        readOnly={true}
                                        disableInteraction={true}
                                    />
                                </Suspense>
                            }
                        />
                    }
                    {
                        temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME) &&     
                        <SttButton
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => enviarImagens.value = true}
                            nomarginleft="true"
                        >
                            {strings.enviarImagens}
                        </SttButton>
                    }
                </>
            }
            {
                temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME) && dadosEnvioImagens.value.dados &&
                <EnvioImagens 
                    enviar={enviarImagens.value} 
                    resetFormulario={() => enviarImagens.value = false}
                    callbackFinalizarEnvio={callbackEnvioImagens}
                />
            }
        </SttContainer>
    )

}

Detalhes.propTypes = {
    id: PropTypes.number.isRequired,
    voltar: PropTypes.func.isRequired
};

export default Detalhes;