import { createSlice } from '@reduxjs/toolkit';
import { estadoInicialForm } from '../componentes/envio-imagens/grid-solicitacoes/estadoInicialForm';

const INITIAL_STATE = {
    filtros: estadoInicialForm
};

export const invioImagensSlice = createSlice({
    name: 'envio-imagens',
    initialState: INITIAL_STATE,
    reducers: {
        setFiltros: (state, action) => {
            return {
                ...state,
                filtros: action.payload
            };
        },
        resetFiltros: () => {
            return INITIAL_STATE;
        }
    }
});

export const { setFiltros, resetFiltros } = invioImagensSlice.actions;

export default invioImagensSlice.reducer;
