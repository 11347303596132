export const ID_EXAME = 'idExame';
export const ID_INSTITUICAO = 'idInstituicao';
export const ID_LAUDO_EMISSAO = 'idLaudoEmissao';
export const TIMESTAMP_INICIO = 'timestampInicio';
export const DESCRICAO_ESTUDO = 'descricaoEstudo';
export const ORIENTACOES = 'orientacoes';
export const CONCLUSAO = 'conclusao';
export const CID_10 = 'cid10';
export const DECS = 'decs';
export const CLASSIFICACAO_RISCO = 'classificacaoRisco';
export const LAUDO_IMPORTANTE = 'laudoImportante';
export const HIPOTESE_DIAGNOSTICA = 'hipoteseDiagnostica';
export const COMPLEMENTO_HIPOTESE_DIAGNOSTICA = 'complementoHipoteseDiagnostica';
export const NUMERO = 'numero';