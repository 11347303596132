import React, { useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { SttHeader, SttMainNavigationBar, SttSplashScreen, MateriaisSuporte, SttTranslateHook, SttNotificationScreen, SttHelpdeskShortcut } from '@stt-componentes/core';

import { temPermissaoRBAC, temPerfilRBAC } from '../secutity/acl';
import { PERMISSOES, PERFIL } from '../common/Constants';

import { getHeaders } from '../request';
import { useSignals, useSignal } from '@preact/signals-react/runtime';
import { signal, useSignalEffect } from '@preact/signals-react';
import usuario from '../signals/usuario';

const submenus = signal([]);
const tabAtual = signal(0);
const openMS = signal(false);
const openSS = signal(false);

const Menu = () => {
    useSignals();
    const navigate = useNavigate();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const location = useLocation();

    const exibirNotificacao = useSignal(false);

    useSignalEffect(() => {
        let menuOptions = [];

        if (usuario) {
            if (!temPermissaoRBAC(usuario, PERMISSOES.ESTOMATO)) {
                navigate(`/nao-autorizado`);
                return;
            }

            exibirNotificacao.value = true;
            if (temPermissaoRBAC(usuario, PERMISSOES.ESTOMATO)) {
                menuOptions = [...menuOptions, {
                    id: '/',
                    text: strings.inicio,
                    button: true,
                    onClick: () => {
                        navigate("/");
                    }
                }];
            }
            if (temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) || temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL)) {
                    menuOptions = [...menuOptions, {
                    id: '/laudos/graves',
                    text: strings.laudosGraves,
                    button: true,
                    onClick: () => {
                        navigate("/laudos/graves");
                    }
                }];
            }
            if (temPermissaoRBAC(usuario, PERMISSOES.SOLICITAR_EXAME)) {
                menuOptions = [...menuOptions, {
                    id: '/solicitacao',
                    text: strings.solicitacao,
                    button: true,
                    onClick: () => {
                        navigate("/solicitacao");
                    }
                }];
            }
            if ((temPermissaoRBAC(usuario, PERMISSOES.VISUALIZAR_SOLICITACAO) || temPermissaoRBAC(usuario, PERMISSOES.CRIAR_EXAME))) {
                menuOptions = [...menuOptions, {
                    id: '/imagens',
                    text: strings.envioImagens,
                    button: true,
                    onClick: () => {
                        navigate("/imagens");
                    }
                }];
            }
            if (temPermissaoRBAC(usuario, PERMISSOES.VISUALIZAR_EXAME)) {
                menuOptions = [...menuOptions, {
                    id: '/exames',
                    text: strings.exames,
                    button: true,
                    onClick: () => {
                        navigate("/exames");
                    }
                }];
            }
            if (temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME_FABRICA)) {
                menuOptions = [...menuOptions, {
                    id: '/laudo',
                    text: strings.laudo,
                    button: true,
                    onClick: () => {
                        navigate("/laudo");
                    }
                }];
            }
            submenus.value = menuOptions;
        }
    });
    
    useSignalEffect(() => {
        submenus.value.forEach((menu, i) => {
            if (location.pathname.includes(menu.id)) {
                tabAtual.value = i;
            }
        });
    });

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => openSS.value = !openSS.value,
                    onClickMateriaisSuporte: () => openMS.value = !openMS.value,
                    strings
                }}
            />
            <SttMainNavigationBar
                titulo={strings.modulo}
                config={global.gConfig}
                submenus={submenus.value}
                selectedTab={tabAtual.value}
                callbackNavigationBar={(a, b) => {}}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                open={openSS.value}
                setOpen={open => openSS.value = open}
            />
            <MateriaisSuporte 
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS.value}
                setOpen={open => openMS.value = open}
            />
            {
                exibirNotificacao.value &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={usuario.value.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={usuario.value}
            />
        </div>
    );
};

export default Menu;