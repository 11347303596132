import * as yup from 'yup'
import { FIELDS } from './fieldNames';
import { TRATAMENTO_PREVIO } from '../../../../common/Constants';

export default (strings) => {
   return yup.object().shape({
        [FIELDS.TRATAMENTO_PREVIO_TIPO]: yup.string().when(FIELDS.TRATAMENTO_PREVIO, {
            is: (val) => val === TRATAMENTO_PREVIO.SIM,
            then: yup.string().required(strings.campoObrigatorio)
        }),
        
    });
};