import React, { lazy, Suspense } from 'react';
import { temPerfilRBAC, temPermissaoRBAC } from '../../secutity/acl';
import { PERFIL, PERMISSOES } from '../../common/Constants';
import useStyles from './style';
import usuario from '../../signals/usuario';
import { 
    SttContainer, 
    SttCircularProgress, 
    SttGrid
} from '@stt-componentes/core';


const Produtividade = () => {
    const classes = useStyles();

    const LaudosEmitidos = lazy(() => import('./laudos-emitidos'));
    const SemLaudo72h = lazy(() => import('./sem-laudo-72h'));
    const SemLaudo = lazy(() => import('./sem-laudo'));
    const ExameMaisAntigoSemLaudo = lazy(() => import('./exame-mais-antigo-sem-laudo'));
    const ExamesComLaudo = lazy(() => import('./exames-com-laudo'));
    const ExamesInvalidos = lazy(() => import('./exames-invalidos'));    
    
    return (
        <SttContainer className={classes.container}>
            <SttGrid container spacing={3}>                    
                {/* Coluna esquerda */} 
                {
                    (temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME) || temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) || temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL)) && 
                    <SttGrid item xs={12} md={6}>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <LaudosEmitidos />
                        </Suspense>
                    </SttGrid>
                }
                {
                    !temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) && !temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL) && !temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME) && 
                    <SttGrid item xs={12} md={6}>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <ExamesComLaudo />
                        </Suspense>
                    </SttGrid>
                }

                {/* Coluna direita */}
                {
                    !temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) && !temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL) && !temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME) && 
                    <SttGrid item xs={12} md={6}>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <ExamesInvalidos />
                        </Suspense>
                    </SttGrid>
                }
                {
                    (temPermissaoRBAC(usuario, PERMISSOES.LAUDAR_EXAME) || temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) || temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL) || temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR)) && 
                    <SttGrid item xs={12} md={6}>                                
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <SemLaudo72h />
                        </Suspense>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <SemLaudo />
                        </Suspense>
                        <Suspense className={classes.card} variant="outlined" fallback={<SttCircularProgress color="primary" />}>
                            <ExameMaisAntigoSemLaudo />
                        </Suspense>
                    </SttGrid>
                }
            </SttGrid>
        </SttContainer>
    )
}

export default Produtividade;