import React, { useContext } from 'react';
import {
    SttTextItem,
    SttTranslateHook
} from '@stt-componentes/core';

const LesoesV1 = (props) => {
    const { exame } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);

    return (
        <div>
            {exame.value.indicacao.lesao_fundamental && <SttTextItem title={strings.lesaoFundamental} content={`${exame.value.indicacao.lesao_fundamental} ${exame.value.indicacao.complemento_lesao_fundamental ? ' - ' + exame.value.indicacao.complemento_lesao_fundamental : ''}`} />}
            {exame.value.indicacao.localizacao_lesao && <SttTextItem title={strings.localizacaoLesao} content={exame.value.indicacao.localizacao_lesao} />}
            {exame.value.indicacao.estrutura_afetada && <SttTextItem title={strings.estruturaAfetada} content={exame.value.indicacao.estrutura_afetada} />}
            {exame.value.indicacao.lado_afetado && <SttTextItem title={strings.ladoAfetado} content={exame.value.indicacao.lado_afetado} />}
            {exame.value.indicacao.regiao_afetada && <SttTextItem title={strings.regiaoAfetada} content={exame.value.indicacao.regiao_afetada} />}
            {exame.value.indicacao.arcada_afetada && <SttTextItem title={strings.arcadaAfetada} content={exame.value.indicacao.arcada_afetada} />}
            {exame.value.indicacao.altura_lesao_fundamental && <SttTextItem title={strings.altura} content={exame.value.indicacao.altura_lesao_fundamental} />}
            {exame.value.indicacao.largura_lesao_fundamental && <SttTextItem title={strings.largura} content={exame.value.indicacao.largura_lesao_fundamental} />}
            {exame.value.indicacao.cor_lesao_fundamental && <SttTextItem title={strings.cor} content={exame.value.indicacao.cor_lesao_fundamental} />}
            {
                exame.value.indicacao.superficie && 
                <SttTextItem title={strings.superficie} content={`${exame.value.indicacao.superficie} ${exame.value.indicacao.observacao_superficie ? ' - ' + exame.value.indicacao.observacao_superficie : ''}`} />
            }
            {exame.value.indicacao.ocorrencia_lesao && <SttTextItem title={strings.ocorrencia} content={exame.value.indicacao.ocorrencia_lesao} />}
            {exame.value.indicacao.palpacao && <SttTextItem title={strings.palpacao} content={exame.value.indicacao.palpacao} />}
            {exame.value.indicacao.tempo_evolucao && <SttTextItem title={strings.tempoEvolucao} content={exame.value.indicacao.tempo_evolucao} />}
            {exame.value.indicacao.tipo_crescimento && <SttTextItem title={strings.tipoCrescimento} content={exame.value.indicacao.tipo_crescimento} />}
            <SttTextItem title={strings.lesaoSaiRaspagem} content={exame.value.indicacao.lesao_sai_raspagem} />
            {
                !exame.value.indicacao.assintomatico &&
                <>
                    {exame.value.indicacao.localizacao_dor && <SttTextItem title={strings.localizacaoDor} content={exame.value.indicacao.localizacao_dor} />}
                    {exame.value.indicacao.espontaneidade_dor && <SttTextItem title={strings.espontaneidadeDor} content={exame.value.indicacao.espontaneidade_dor} />}
                    {exame.value.indicacao.duracao_dor && <SttTextItem title={strings.duracaoDor} content={exame.value.indicacao.duracao_dor} />}
                    {exame.value.indicacao.intensidade_dor && <SttTextItem title={strings.intensidadeDor} content={exame.value.indicacao.intensidade_dor} />}
                    {exame.value.indicacao.tipo_dor && <SttTextItem title={strings.tipoDor} content={exame.value.indicacao.tipo_dor} />}
                </> ||
                <SttTextItem title={strings.assintomatico} content={exame.value.indicacao.assintomatico_hr} />
            }
        </div>
    )

}

export default LesoesV1;