import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    isAuthenticated: false,
    user: {
        nome: '',
        email: '',
        foto: ''
    },
    examesSemLaudo: null
};

export const indexSlice = createSlice({
    name: 'index',
    initialState: INITIAL_STATE,
    reducers: {
        setAuthenticated: (state) => {
            return {
                ...state,
                isAuthenticated: true
            };
        },
        setUser: (state, action) => {
            return {
                ...state,
                user: action.payload
            };
        },
        logout: (state) => {
            return {
                ...INITIAL_STATE
            }
        },
        setExamesSemLaudo: (state, action) => {
            return {
                ...state,
                examesSemLaudo: action.payload
            }
        }
    }
});

export const { setAuthenticated, setUser, logout, setExamesSemLaudo } = indexSlice.actions;

export default indexSlice.reducer;
