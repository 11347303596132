import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field, useFormikContext } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal } from '@preact/signals-react';
import { 
    UNIDADE_MEDIDA as UNIDADE_MEDIDA_LESAO,
    LOCALIZACAO_DOR as DOR_LOCALIZACAO,
    ESPONTANEIDADE_DOR as DOR_ESPONTANEIDADE,
    INTENSIDADE_DOR as DOR_INTENSIDADE,
    DURACAO_DOR as DOR_DURACAO,
    TIPO_DOR as DOR_TIPO,
    QUEIMACAO as SINTOMA_QUEIMACAO,
    SENSIBILIDADE as SINTOMA_SENSIBILIDADE,
    SAI_A_RASPAGEM,
    VERSAO,
    LADO_AFETADO as LADO_LESAO,
    ARCADA_AFETADA as ARCADA_LESAO,
    OCORRENCIA_LESAO as OCORRENCIA
} from '../../../common/Constants';
import upperFirst from 'lodash.upperfirst';
import { 
    LESOES, 
    LESAO_FUNDAMENTAL,
    COMPLEMENTO_LESAO_FUNDAMENTAL,
    LOCALIZACAO,
    LARGURA,
    ALTURA,
    UNIDADE_MEDIDA,
    TAMANHO,
    COR,
    OUTRA_COR,
    SUPERFICIE,
    OBSERVACAO_SUPERFICIE,
    PALPACAO,
    TEMPO_EVOLUCAO,
    TEMPO,
    TEMPO_EVOLUCAO_NAO_CONHECIDO,
    TIPO_CRESCIMENTO,
    PACIENTE_ASSINTOMATICO,
    LOCALIZACAO_DOR,
    ESPONTANEIDADE_DOR,
    INTENSIDADE_DOR,
    DURACAO_DOR,
    TIPO_DOR,
    QUEIMACAO,
    SENSIBILIDADE,
    LESAO_SAI_RASPAGEM,
    ESTRUTURA_AFETADA,
    REGIAO_AFETADA,
    LADO_AFETADO,
    ARCADA_AFETADA,
    OCORRENCIA_LESAO
} from './fieldNames';
import { 
    SttGrid,
    SttRadioGroup, 
    SttRadioButton,
    SttFormControl,
    SttFormControlLabel,
    SttHeading,
    SttNumberInput,
    SttFormHelperText,
    SttInput,
    SttAutocomplete,
    SttFormGroup,
    SttCheckbox,
    SttTranslateHook 
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: '10px'
    },
    radioGroup: {
        marginTop: theme.spacing(1)
    },
    checkboxCenter: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const Form = (props) => {
    const { 
        indiceLesao, 
        readOnly = {},
        required = {
            tamanho: true,
            palpacao: true,
            tempoEvolucao: true,
            tipoCrescimento: true,
            sintomatologia: true,
            saiRaspagem: true
        },
        versao = VERSAO.NOVA,
        contextoLaudo = false,
        refs: {
            lesaoFundamentalRef,
            tamanhoRef,
            estruturaRef,
            regiaoRef,
            ladoAfetadoRef,
            arcadaAfetadaRef,
            corRef,
            superficieRef,
            ocorrenciaLesaoRef,
            palpacaoRef,
            tempoEvolucaoRef,
            dorRef,
            sensibilidadeRef
        }
    } = props;
    const { values, setFieldValue } = useFormikContext();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    
    const listaLesaoFundamental = useSignal([]);
    const listaLocalizacaoLesao = useSignal([]);
    const listaCor = useSignal([]);
    const listaSuperficie = useSignal([]);
    const listaPalpacao = useSignal([]);
    const listaEspecificacaoTempo = useSignal([]);
    const listaTipoCrescimento = useSignal([]);
    const listaRegiaoAfetada = useSignal([]);
    const listaEstruturaAfetada = useSignal([]);
    
    useSignals();

    useEffect(() => {
        const ESTOMATO_API_BASE_URL = global.gConfig.url_base_estomato;
        
        axios
            .get(`${ESTOMATO_API_BASE_URL}/lesao-fundamental`, { headers: getHeaders() })
            .then((response) => {
                listaLesaoFundamental.value = response.data;
                // Se na solicitação não foi indicada nenhuma lesão, deve ser assinalada a opção
                // "Sem lesão palpável e visualizável", indicada como "lesão oculta" no momento do laudo
                if (contextoLaudo && !values[LESOES][indiceLesao.value][LESAO_FUNDAMENTAL]) {
                    const grupoLesaoFundamental = listaLesaoFundamental.value.filter(grupo => grupo.lesoes.some(lesao => lesao.descricao === 'Outra'));
                    const itemSemLesao = grupoLesaoFundamental[0].lesoes.filter(item => item.lesao_oculta);
                    setFieldValue(`${LESOES}[${indiceLesao.value}].${LESAO_FUNDAMENTAL}`, itemSemLesao[0].id);
                }
            })
            .catch(err => console.log(err));
        
        axios
            .get(`${ESTOMATO_API_BASE_URL}/localizacao-lesao`, { headers: getHeaders() })
            .then((response) => {
                listaLocalizacaoLesao.value = response.data;
            })
            .catch(err => console.log(err));
    
        axios
            .get(`${ESTOMATO_API_BASE_URL}/cor`, { headers: getHeaders() })
            .then((response) => {
                listaCor.value = response.data;
            })
            .catch(err => console.log(err));

        axios
            .get(`${ESTOMATO_API_BASE_URL}/superficie`, { headers: getHeaders() })
            .then((response) => {
                listaSuperficie.value = response.data;
            })
            .catch(err => console.log(err));

        axios
            .get(`${ESTOMATO_API_BASE_URL}/palpacao`, { headers: getHeaders() })
            .then((response) => {
                listaPalpacao.value = response.data;
            })
            .catch(err => console.log(err));

        axios
            .get(`${ESTOMATO_API_BASE_URL}/especificacao-tempo`, { headers: getHeaders() })
            .then((response) => {
                listaEspecificacaoTempo.value = response.data;
            })
            .catch(err => console.log(err));

        axios
            .get(`${ESTOMATO_API_BASE_URL}/tipo-crescimento`, { headers: getHeaders() })
            .then((response) => {
                listaTipoCrescimento.value = response.data;
            })
            .catch(err => console.log(err));
        
        if (versao === VERSAO.ANTIGA) {
            axios
                .get(`${ESTOMATO_API_BASE_URL}/regiao-afetada`, { headers: getHeaders() })
                .then((response) => {
                    listaRegiaoAfetada.value = response.data;
                })
                .catch(err => console.log(err));

            axios
                .get(`${ESTOMATO_API_BASE_URL}/estrutura-afetada`, { headers: getHeaders() })
                .then((response) => {
                    listaEstruturaAfetada.value = response.data;
                })
                .catch(err => console.log(err));
        }
    }, []);

    const lesaoPossuiComplemento = (idLesaoSelecionada) => {
        if (!idLesaoSelecionada) {
            return false;
        }
        const grupoLesaoFundamental = listaLesaoFundamental.value.filter(grupo => grupo.lesoes.some(lesao => lesao.id === parseInt(idLesaoSelecionada)));
        const lesao = grupoLesaoFundamental[0].lesoes.filter(lesao => lesao.id === parseInt(idLesaoSelecionada));
        return lesao[0].complemento;
    }

    const outraCor = (idCor) => {
        if (!idCor) {
            return false;
        }
        const cor = listaCor.value.filter(cor => cor.id === parseInt(idCor));
        return cor[0].complemento;
    }

    return (
        listaLesaoFundamental.value.length > 0 && listaCor.value.length > 0 &&
        <SttGrid container spacing={3} className={classes.form}>
            <SttGrid item xs={12}>
                <Field name={`${LESOES}[${indiceLesao.value}].${LESAO_FUNDAMENTAL}`}>
                    {({
                        field,
                        meta,
                    }) => (
                        <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                            <SttHeading variant="h4" color="primary" required>{strings.lesaoFundamental}</SttHeading>
                            <div ref={lesaoFundamentalRef}></div>
                            <SttRadioGroup>
                                {
                                    listaLesaoFundamental.value.map((grupoLesao, idxGrupo) => (
                                        <div key={idxGrupo} className={classes.radioGroup}>
                                            <SttHeading variant="h5" color="primary">{grupoLesao.descricao}</SttHeading>
                                            {
                                                grupoLesao.lesoes.map((lesao, idxLesao) => (
                                                    <SttFormControlLabel 
                                                        key={idxLesao}
                                                        {...field} 
                                                        control={
                                                            <SttRadioButton  
                                                                type="radio"
                                                                value={lesao.id}
                                                                color="primary"
                                                                checked={field.value == lesao.id}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    if (!lesaoPossuiComplemento(field.value)) {
                                                                        setFieldValue(`${LESOES}[${indiceLesao.value}].${COMPLEMENTO_LESAO_FUNDAMENTAL}`, '');
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label={lesao.descricao}
                                                    />
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </SttRadioGroup>
                            {
                                meta.touched && meta.error && 
                                <SttFormHelperText error>
                                    {meta.error}
                                </SttFormHelperText>
                            }
                        </SttFormControl>
                    )}
                </Field>
            </SttGrid>
            {
                lesaoPossuiComplemento(values[LESOES][indiceLesao.value][LESAO_FUNDAMENTAL]) && 
                <SttGrid item xs={12}>
                    <Field name={`${LESOES}[${indiceLesao.value}].${COMPLEMENTO_LESAO_FUNDAMENTAL}`}>
                        {({
                            field,
                            meta
                        }) => (
                            
                            <SttInput 
                                multiline
                                minRows={3}
                                required={lesaoPossuiComplemento(values[LESOES][indiceLesao.value][LESAO_FUNDAMENTAL])}
                                error={meta.touched && meta.error ? true : false}
                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                {...field}
                                label={strings.complemento}
                            />
                        )}
                    </Field>
                </SttGrid>
            }
            <SttGrid item xs={12}>
                <Field name={`${LESOES}[${indiceLesao.value}].${LOCALIZACAO}`}>
                    {({
                        field,
                        meta,
                    }) => (
                        <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                            <SttHeading variant="h4" color="primary" required>{strings.localizacao}</SttHeading>
                            <SttRadioGroup row>
                                {
                                    listaLocalizacaoLesao.value.map((localizacao, idx) => (
                                        <SttFormControlLabel 
                                            key={idx}
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={localizacao.id}
                                                    color="primary"
                                                    checked={field.value == localizacao.id}    
                                                />
                                            }
                                            label={localizacao.descricao}
                                        />
                                    ))
                                }
                            </SttRadioGroup>
                            {
                                meta.touched && meta.error && 
                                <SttFormHelperText error>
                                    {meta.error}
                                </SttFormHelperText>
                            }
                        </SttFormControl>
                    )}
                </Field>
            </SttGrid>
            {
                versao === VERSAO.ANTIGA && 
                <>
                    <SttGrid item xs={12}>
                        <Field name={`${LESOES}[${indiceLesao.value}].${ESTRUTURA_AFETADA}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttHeading variant="h4" color="primary" required>{strings.estruturaAfetada}</SttHeading>
                                    <div ref={estruturaRef}></div>
                                    <SttFormGroup>
                                        {
                                            listaEstruturaAfetada.value.map((estrutura) => (
                                                <SttFormControlLabel
                                                    control={
                                                        <SttCheckbox
                                                            {...field}
                                                            value={estrutura.id}
                                                            color="primary"
                                                            checked={field.value?.includes(estrutura.id.toString())}
                                                        />
                                                    }
                                                    key={estrutura.id}
                                                    label={estrutura.descricao}
                                                />
                                            ))
                                        }
                                    </SttFormGroup>
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttFormControl>
                            )}
                        </Field>
                    </SttGrid>
                    <SttGrid item xs={12}>
                        <Field name={`${LESOES}[${indiceLesao.value}].${LADO_AFETADO}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttHeading variant="h4" color="primary" required>{strings.ladoAfetado}</SttHeading>
                                    <div ref={ladoAfetadoRef}></div>
                                    <SttRadioGroup row>
                                        <SttFormControlLabel
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={LADO_LESAO.UNILATERAL}
                                                    color="primary"
                                                    checked={field.value == LADO_LESAO.UNILATERAL}
                                                />
                                            }
                                            label={strings.unilateral}
                                        />
                                        <SttFormControlLabel
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={LADO_LESAO.BILATERAL}
                                                    color="primary"
                                                    checked={field.value == LADO_LESAO.BILATERAL}
                                                />
                                            }
                                            label={strings.bilateral}
                                        />
                                        <SttFormControlLabel
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={LADO_LESAO.LINHA_MEDIA}
                                                    color="primary"
                                                    checked={field.value == LADO_LESAO.LINHA_MEDIA}
                                                />
                                            }
                                            label={strings.linhaMedia}
                                        />
                                        <SttFormControlLabel
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={LADO_LESAO.NAO_APLICA}
                                                    color="primary"
                                                    checked={field.value == LADO_LESAO.NAO_APLICA}
                                                />
                                            }
                                            label={strings.naoSeAplica}
                                        />
                                    </SttRadioGroup>
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttFormControl>
                            )}
                        </Field>
                    </SttGrid>
                    <SttGrid item xs={12}>
                        <Field name={`${LESOES}[${indiceLesao.value}].${REGIAO_AFETADA}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttHeading variant="h4" color="primary" required>{strings.regiaoAfetada}</SttHeading>
                                    <div ref={regiaoRef}></div>
                                    <SttFormGroup row>
                                        {
                                            listaRegiaoAfetada.value.map((regiao) => (
                                                <SttFormControlLabel
                                                    control={
                                                        <SttCheckbox
                                                            {...field}
                                                            value={regiao.id}
                                                            color="primary"
                                                            checked={field.value?.includes(regiao.id.toString())}
                                                        />
                                                    }
                                                    key={regiao.id}
                                                    label={regiao.descricao}
                                                />
                                            ))
                                        }
                                    </SttFormGroup>
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttFormControl>
                            )}
                        </Field>
                    </SttGrid>
                    <SttGrid item xs={12}>
                        <Field name={`${LESOES}[${indiceLesao.value}].${ARCADA_AFETADA}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttHeading variant="h4" color="primary" required>{strings.arcadaAfetada}</SttHeading>
                                    <div ref={arcadaAfetadaRef}></div>
                                    <SttRadioGroup row>
                                        <SttFormControlLabel
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={ARCADA_LESAO.SUPERIOR}
                                                    color="primary"
                                                    checked={field.value == ARCADA_LESAO.SUPERIOR}
                                                />
                                            }
                                            label={strings.superior}
                                        />
                                        <SttFormControlLabel
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={ARCADA_LESAO.INFERIOR}
                                                    color="primary"
                                                    checked={field.value == ARCADA_LESAO.INFERIOR}
                                                />
                                            }
                                            label={strings.inferior}
                                        />
                                        <SttFormControlLabel
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={ARCADA_LESAO.AMBAS}
                                                    color="primary"
                                                    checked={field.value == ARCADA_LESAO.AMBAS}
                                                />
                                            }
                                            label={strings.ambas}
                                        />
                                        <SttFormControlLabel
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={ARCADA_LESAO.NAO_APLICA}
                                                    color="primary"
                                                    checked={field.value == ARCADA_LESAO.NAO_APLICA}
                                                />
                                            }
                                            label={strings.naoSeAplica}
                                        />
                                    </SttRadioGroup>
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttFormControl>
                            )}
                        </Field>
                    </SttGrid>
                </>
            }
            <SttGrid item xs={12}>
                <SttHeading variant="h4" color="primary" required={required.tamanho}>{strings.tamanho}</SttHeading>
                <div ref={tamanhoRef}></div>
                <Field name={`${LESOES}[${indiceLesao.value}].${TAMANHO}`}>
                    {() => (
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={4}>
                                <Field name={`${LESOES}[${indiceLesao.value}].${TAMANHO}.${ALTURA}`}>
                                    {({
                                        field,
                                        meta
                                    }) => (
                                        <SttNumberInput 
                                            inputProps={{
                                                maxLength: 3
                                            }}
                                            disabled={readOnly.tamanho}
                                            error={meta.touched && meta.error ? true : false}
                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                            label={strings.altura}
                                            {...field}
                                            required={required.tamanho}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                            <SttGrid item xs={4}>
                                <Field name={`${LESOES}[${indiceLesao.value}].${TAMANHO}.${LARGURA}`}>
                                    {({
                                        field,
                                        meta
                                    }) => (
                                        <SttNumberInput 
                                            inputProps={{
                                                maxLength: 3
                                            }}
                                            disabled={readOnly.tamanho}
                                            error={meta.touched && meta.error ? true : false}
                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                            label={strings.largura}
                                            {...field}
                                            required={required.tamanho}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                            <SttGrid item xs={4}>
                                <Field name={`${LESOES}[${indiceLesao.value}].${TAMANHO}.${UNIDADE_MEDIDA}`}>
                                    {({
                                        field: { name, value, onBlur },
                                        form,
                                        meta,
                                    }) => (
                                        <SttAutocomplete 
                                            inputprops={{
                                                name: name,
                                                label: strings.unidadeMedida,
                                                error: meta.touched && meta.error ? meta.error : undefined,
                                                required: required.tamanho
                                            }}
                                            disabled={readOnly.tamanho}
                                            getOptionLabel={option => option ? option : ''}
                                            options={Object.keys(UNIDADE_MEDIDA_LESAO).map(key => key.toLowerCase())}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e, item) => form.setFieldValue(`${LESOES}[${indiceLesao.value}].${TAMANHO}.${UNIDADE_MEDIDA}`, item || null)}
                                        />
                                    )}
                                </Field>
                            </SttGrid>
                        </SttGrid>
                    )}
                </Field>
            </SttGrid>
            <SttGrid item xs={12}>
                <Field name={`${LESOES}[${indiceLesao.value}].${COR}`}>
                    {({
                        field,
                        meta,
                    }) => (
                        <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                            <SttHeading variant="h4" color="primary" required>{strings.cor}</SttHeading>
                            <div ref={corRef}></div>
                            <SttRadioGroup>
                                {
                                    listaCor.value.map((cor, idx) => (
                                        <SttFormControlLabel 
                                            key={idx}
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={cor.id}
                                                    color="primary"
                                                    checked={field.value == cor.id}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        if (!outraCor(field.value)) {
                                                            setFieldValue(`${LESOES}[${indiceLesao.value}].${OUTRA_COR}`, '');
                                                        }
                                                    }}   
                                                />
                                            }
                                            label={cor.descricao}
                                        />
                                    ))
                                }
                            </SttRadioGroup>
                            {
                                meta.touched && meta.error && 
                                <SttFormHelperText error>
                                    {meta.error}
                                </SttFormHelperText>
                            }
                        </SttFormControl>
                    )}
                </Field>
            </SttGrid>
            {
                outraCor(values[LESOES][indiceLesao.value][COR]) && 
                <SttGrid item xs={12}>
                    <Field name={`${LESOES}[${indiceLesao.value}].${OUTRA_COR}`} >
                        {({
                            field,
                            meta
                        }) => (
                            
                            <SttInput 
                                inputProps={{
                                    maxLength: 50,
                                }}
                                required={outraCor(values[LESOES][indiceLesao.value][COR])}
                                error={meta.touched && meta.error ? true : false}
                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                {...field}
                                label={strings.outraCor}
                            />
                        )}
                    </Field>
                </SttGrid>
            }
            <SttGrid item xs={12}>
                <Field name={`${LESOES}[${indiceLesao.value}].${SUPERFICIE}`}>
                    {({
                        field,
                        meta,
                    }) => (
                        <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                            <SttHeading variant="h4" color="primary" required>{strings.superficie}</SttHeading>
                            <div ref={superficieRef}></div>
                            <SttRadioGroup row>
                                {
                                    listaSuperficie.value.map((superficie, idx) => (
                                        <SttFormControlLabel 
                                            key={idx}
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={superficie.id}
                                                    color="primary"
                                                    checked={field.value == superficie.id}    
                                                />
                                            }
                                            label={superficie.descricao}
                                        />
                                    ))
                                }
                            </SttRadioGroup>
                            {
                                meta.touched && meta.error && 
                                <SttFormHelperText error>
                                    {meta.error}
                                </SttFormHelperText>
                            }
                        </SttFormControl>
                    )}
                </Field>
            </SttGrid>
            <SttGrid item xs={12}>
                <Field name={`${LESOES}[${indiceLesao.value}].${OBSERVACAO_SUPERFICIE}`}>
                    {({
                        field,
                        meta
                    }) => (
                        
                        <SttInput 
                            error={meta.touched && meta.error ? true : false}
                            helperText={meta.touched && meta.error ? meta.error : undefined}
                            {...field}
                            label={strings.observacaoSuperficie}
                        />
                    )}
                </Field>
            </SttGrid>
            {
                versao === VERSAO.ANTIGA &&
                <SttGrid item xs={12}>
                    <Field name={`${LESOES}[${indiceLesao.value}].${OCORRENCIA_LESAO}`}>
                        {({
                            field,
                            meta,
                        }) => (
                            <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                <SttHeading variant="h4" color="primary" required>{strings.ocorrenciaLesao}</SttHeading>
                                <div ref={ocorrenciaLesaoRef}></div>
                                <SttRadioGroup row>
                                    <SttFormControlLabel
                                        {...field} 
                                        control={
                                            <SttRadioButton  
                                                type="radio"
                                                value={OCORRENCIA.UNICA}
                                                color="primary"
                                                checked={field.value == OCORRENCIA.UNICA}
                                            />
                                        }
                                        label={strings.lesaoUnica}
                                    />
                                    <SttFormControlLabel
                                        {...field} 
                                        control={
                                            <SttRadioButton  
                                                type="radio"
                                                value={OCORRENCIA.MULTIPLAS}
                                                color="primary"
                                                checked={field.value == OCORRENCIA.MULTIPLAS}
                                            />
                                        }
                                        label={strings.lesoesMultiplas}
                                    />
                                    <SttFormControlLabel
                                        {...field} 
                                        control={
                                            <SttRadioButton  
                                                type="radio"
                                                value={OCORRENCIA.SEM_LESAO}
                                                color="primary"
                                                checked={field.value == OCORRENCIA.SEM_LESAO}
                                            />
                                        }
                                        label={strings.semLesaoIdentificavel}
                                    />
                                </SttRadioGroup>
                                {
                                    meta.touched && meta.error && 
                                    <SttFormHelperText error>
                                        {meta.error}
                                    </SttFormHelperText>
                                }
                            </SttFormControl>
                        )}
                    </Field>
                </SttGrid>
            }
            <SttGrid item xs={12}>
                <Field name={`${LESOES}[${indiceLesao.value}].${PALPACAO}`}>
                    {({
                        field,
                        meta,
                    }) => (
                        <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                            <SttHeading variant="h4" color="primary" required={required.palpacao}>{strings.palpacao}</SttHeading>
                            <div ref={palpacaoRef}></div>
                            <SttRadioGroup row>
                                {
                                    listaPalpacao.value.map((palpacao, idx) => (
                                        <SttFormControlLabel 
                                            key={idx}
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={palpacao.id}
                                                    color="primary"
                                                    checked={field.value == palpacao.id}
                                                    disabled={readOnly.palpacao}
                                                />
                                            }
                                            label={palpacao.descricao}
                                        />
                                    ))
                                }
                            </SttRadioGroup>
                            {
                                meta.touched && meta.error && 
                                <SttFormHelperText error>
                                    {meta.error}
                                </SttFormHelperText>
                            }
                        </SttFormControl>
                    )}
                </Field>
            </SttGrid>
            <SttGrid item xs={12}>
                <SttHeading variant="h4" color="primary" required={required.tempoEvolucao}>{strings.tempoEvolucao}</SttHeading>
                <div ref={tempoEvolucaoRef}></div>
                <SttGrid container spacing={3}>
                    <SttGrid item xs={8}>
                        <Field name={`${LESOES}[${indiceLesao.value}].${TEMPO_EVOLUCAO}`}>
                            {() => (
                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={6}>
                                        <Field name={`${LESOES}[${indiceLesao.value}].${TEMPO_EVOLUCAO}.${TEMPO}`}>
                                            {({
                                                field,
                                                meta
                                            }) => (
                                                <SttNumberInput 
                                                    inputProps={{ maxLength: 3 }}
                                                    disabled={values[LESOES][indiceLesao.value][TEMPO_EVOLUCAO_NAO_CONHECIDO] || readOnly.tempoEvolucao}
                                                    error={meta.touched && meta.error ? true : false}
                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    label={strings.tempo}
                                                    required={required.tempoEvolucao}
                                                    {...field}
                                                />
                                            )}
                                        </Field>
                                    </SttGrid>
                                    <SttGrid item xs={6}>
                                        <Field name={`${LESOES}[${indiceLesao.value}].${TEMPO_EVOLUCAO}.${UNIDADE_MEDIDA}`}>
                                            {({
                                                field: { name, value, onBlur },
                                                form,
                                                meta,
                                            }) => (
                                                <SttAutocomplete 
                                                    inputprops={{
                                                        name: name,
                                                        label: strings.unidadeMedida,
                                                        error: meta.touched && meta.error ? meta.error : undefined,
                                                        required: required.tempoEvolucao
                                                    }}
                                                    getOptionLabel={option => option ? option.descricao : ''}
                                                    options={listaEspecificacaoTempo.value}
                                                    value={value}
                                                    onBlur={onBlur}
                                                    disabled={values[LESOES][indiceLesao.value][TEMPO_EVOLUCAO_NAO_CONHECIDO] || readOnly.tempoEvolucao}
                                                    onChange={(e, item) => form.setFieldValue(`${LESOES}[${indiceLesao.value}].${TEMPO_EVOLUCAO}.${UNIDADE_MEDIDA}`, item || null)}
                                                />
                                            )}
                                        </Field>
                                    </SttGrid>
                                </SttGrid>
                            )}
                        </Field>
                    </SttGrid>
                    <SttGrid item xs={4} className={classes.checkboxCenter}>
                        <Field name={`${LESOES}[${indiceLesao.value}].${TEMPO_EVOLUCAO_NAO_CONHECIDO}`}>
                            {({
                                field: { onChange, checked, ...other }
                            }) => (
                                <SttFormControl variant="outlined">
                                    <SttFormGroup row>
                                        <SttFormControlLabel
                                            control={
                                                <SttCheckbox
                                                    {...other}
                                                    onChange={(event) => {
                                                        setFieldValue(`${LESOES}[${indiceLesao.value}].${TEMPO_EVOLUCAO_NAO_CONHECIDO}`, event.target.checked);
                                                        setFieldValue(`${LESOES}[${indiceLesao.value}].${TEMPO_EVOLUCAO}.${TEMPO}`, '');
                                                        setFieldValue(`${LESOES}[${indiceLesao.value}].${TEMPO_EVOLUCAO}.${UNIDADE_MEDIDA}`, null);
                                                    }}
                                                    color="primary"
                                                    checked={checked || values[LESOES][indiceLesao.value][TEMPO_EVOLUCAO_NAO_CONHECIDO]}
                                                    disabled={readOnly.tempoEvolucao}
                                                />
                                            }
                                            label={strings.naoConhecido}
                                        />
                                    </SttFormGroup>
                                </SttFormControl>
                            )}
                        </Field>
                    </SttGrid>
                </SttGrid>
            </SttGrid>
            <SttGrid item xs={12}>
                <Field name={`${LESOES}[${indiceLesao.value}].${TIPO_CRESCIMENTO}`}>
                    {({
                        field,
                        meta,
                    }) => (
                        <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                            <SttHeading variant="h4" color="primary">{strings.tipoCrescimento}</SttHeading>
                            <SttRadioGroup row>
                                {
                                    listaTipoCrescimento.value.map((tipoCrescimento, idx) => (
                                        <SttFormControlLabel 
                                            key={idx}
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={tipoCrescimento.id}
                                                    color="primary"
                                                    checked={field.value == tipoCrescimento.id}
                                                    disabled={readOnly.tipoCrescimento}
                                                />
                                            }
                                            label={tipoCrescimento.descricao}
                                        />
                                    ))
                                }
                                {
                                    meta.touched && meta.error && 
                                    <SttFormHelperText error>
                                        {meta.error}
                                    </SttFormHelperText>
                                }
                            </SttRadioGroup>
                        </SttFormControl>
                    )}
                </Field>
            </SttGrid>
            <SttGrid item xs={12} className={classes.checkboxCenter}>
                <Field name={`${LESOES}[${indiceLesao.value}].${PACIENTE_ASSINTOMATICO}`}>
                    {({ field: { onChange, checked, ...other } }) => (
                        <SttFormControl variant="outlined">
                            <SttHeading variant="h4" color="primary">{strings.sintomatologia}</SttHeading>
                            <SttFormGroup row>
                                <SttFormControlLabel
                                    control={
                                        <SttCheckbox
                                            {...other}
                                            color="primary"
                                            checked={checked || values[LESOES][indiceLesao.value][PACIENTE_ASSINTOMATICO]}
                                            onChange={(e) => {
                                                setFieldValue(`${LESOES}[${indiceLesao.value}].${PACIENTE_ASSINTOMATICO}`, e.target.checked);
                                                setFieldValue(`${LESOES}[${indiceLesao.value}].${LOCALIZACAO_DOR}`, '');
                                                setFieldValue(`${LESOES}[${indiceLesao.value}].${ESPONTANEIDADE_DOR}`, '');
                                                setFieldValue(`${LESOES}[${indiceLesao.value}].${DURACAO_DOR}`, '');
                                                setFieldValue(`${LESOES}[${indiceLesao.value}].${INTENSIDADE_DOR}`, '');
                                                setFieldValue(`${LESOES}[${indiceLesao.value}].${TIPO_DOR}`, '');
                                                setFieldValue(`${LESOES}[${indiceLesao.value}].${QUEIMACAO}`, '');
                                                setFieldValue(`${LESOES}[${indiceLesao.value}].${SENSIBILIDADE}`, '');
                                            }}
                                            disabled={readOnly.sintomatologia}
                                        />
                                    }
                                    label={strings.pacienteAssintomatico}
                                />
                            </SttFormGroup>
                        </SttFormControl>
                    )}
                </Field>
            </SttGrid>
            {
                !values[LESOES][indiceLesao.value][PACIENTE_ASSINTOMATICO] &&
                <>
                    <div ref={dorRef}></div>
                    <SttGrid item xs={12}>
                        <SttHeading variant="h5" color="primary" required={required.sintomatologia}>{strings.dor}</SttHeading>
                        <Field name={`${LESOES}[${indiceLesao.value}].${LOCALIZACAO_DOR}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttRadioGroup row>
                                        {
                                            Object.keys(DOR_LOCALIZACAO).map((localizacao, idx) => (
                                                <SttFormControlLabel 
                                                    key={idx}
                                                    {...field} 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value={DOR_LOCALIZACAO[localizacao]}
                                                            color="primary"
                                                            checked={field.value == DOR_LOCALIZACAO[localizacao]}
                                                            disabled={readOnly.sintomatologia}
                                                        />
                                                    }
                                                    label={upperFirst(localizacao.toLowerCase())}
                                                />
                                            ))
                                        }
                                    </SttRadioGroup>
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttFormControl>
                            )}
                        </Field>
                        <br/>
                        <Field name={`${LESOES}[${indiceLesao.value}].${ESPONTANEIDADE_DOR}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttRadioGroup row>
                                        {
                                            Object.keys(DOR_ESPONTANEIDADE).map((espontaneidade, idx) => (
                                                <SttFormControlLabel 
                                                    key={idx}
                                                    {...field} 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value={DOR_ESPONTANEIDADE[espontaneidade]}
                                                            color="primary"
                                                            checked={field.value == DOR_ESPONTANEIDADE[espontaneidade]}
                                                            disabled={readOnly.sintomatologia}
                                                        />
                                                    }
                                                    label={upperFirst(espontaneidade.toLowerCase())}
                                                />
                                            ))
                                        }
                                    </SttRadioGroup>
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttFormControl>
                            )}
                        </Field>
                        <br/>
                        <Field name={`${LESOES}[${indiceLesao.value}].${DURACAO_DOR}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttRadioGroup row>
                                        {
                                            Object.keys(DOR_DURACAO).map((duracao, idx) => (
                                                <SttFormControlLabel 
                                                    key={idx}
                                                    {...field} 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value={DOR_DURACAO[duracao]}
                                                            color="primary"
                                                            checked={field.value == DOR_DURACAO[duracao]}
                                                            disabled={readOnly.sintomatologia}
                                                        />
                                                    }
                                                    label={upperFirst(duracao.toLowerCase())}
                                                />
                                            ))
                                        }
                                    </SttRadioGroup>
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttFormControl>
                            )}
                        </Field>
                        <br/>
                        <Field name={`${LESOES}[${indiceLesao.value}].${INTENSIDADE_DOR}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttRadioGroup row>
                                        {
                                            Object.keys(DOR_INTENSIDADE).map((intensidade, idx) => (
                                                <SttFormControlLabel 
                                                    key={idx}
                                                    {...field} 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value={DOR_INTENSIDADE[intensidade]}
                                                            color="primary"
                                                            checked={field.value == DOR_INTENSIDADE[intensidade]}
                                                            disabled={readOnly.sintomatologia}
                                                        />
                                                    }
                                                    label={upperFirst(intensidade.toLowerCase())}
                                                />
                                            ))
                                        }
                                    </SttRadioGroup>
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttFormControl>
                            )}
                        </Field>
                        <br/>
                        <Field name={`${LESOES}[${indiceLesao.value}].${TIPO_DOR}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttRadioGroup row>
                                        {
                                            Object.keys(DOR_TIPO).map((tipo, idx) => (
                                                <SttFormControlLabel 
                                                    key={idx}
                                                    {...field} 
                                                    control={
                                                        <SttRadioButton  
                                                            type="radio"
                                                            value={DOR_TIPO[tipo]}
                                                            color="primary"
                                                            checked={field.value == DOR_TIPO[tipo]}
                                                            disabled={readOnly.sintomatologia}
                                                        />
                                                    }
                                                    label={upperFirst(tipo.toLowerCase())}
                                                />
                                            ))
                                        }
                                    </SttRadioGroup>
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttFormControl>
                            )}
                        </Field>
                    </SttGrid>
                    <SttGrid item xs={12}>
                        <Field name={`${LESOES}[${indiceLesao.value}].${QUEIMACAO}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttHeading variant="h5" color="primary" required={required.sintomatologia}>{strings.queimacao}</SttHeading>
                                    <SttRadioGroup row>
                                        <SttFormControlLabel 
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={SINTOMA_QUEIMACAO.SIM}
                                                    color="primary"
                                                    checked={field.value === SINTOMA_QUEIMACAO.SIM}
                                                    disabled={readOnly.sintomatologia}
                                                />
                                            }
                                            label={strings.sim}
                                        />
                                        <SttFormControlLabel 
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={SINTOMA_QUEIMACAO.NAO}
                                                    color="primary"
                                                    checked={field.value === SINTOMA_QUEIMACAO.NAO}
                                                    disabled={readOnly.sintomatologia}
                                                />
                                            }
                                            label={strings.nao}
                                        />
                                    </SttRadioGroup>
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttFormControl>
                            )}
                        </Field>
                    </SttGrid>
                    <SttGrid item xs={12}>
                        <Field name={`${LESOES}[${indiceLesao.value}].${SENSIBILIDADE}`}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttHeading variant="h5" color="primary" required={required.sintomatologia}>{strings.alteracaoSensibilidade}</SttHeading>
                                    <div ref={sensibilidadeRef}></div>
                                    <SttRadioGroup row>
                                        <SttFormControlLabel 
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={SINTOMA_SENSIBILIDADE.NAO}
                                                    color="primary"
                                                    checked={field.value === SINTOMA_SENSIBILIDADE.NAO}
                                                    disabled={readOnly.sintomatologia}
                                                />
                                            }
                                            label={strings.nao}
                                        />
                                        <SttFormControlLabel 
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={SINTOMA_SENSIBILIDADE.PERDA}
                                                    color="primary"
                                                    checked={field.value === SINTOMA_SENSIBILIDADE.PERDA}
                                                    disabled={readOnly.sintomatologia}
                                                />
                                            }
                                            label={strings.perdaSensibilidade}
                                        />
                                        <SttFormControlLabel 
                                            {...field} 
                                            control={
                                                <SttRadioButton  
                                                    type="radio"
                                                    value={SINTOMA_SENSIBILIDADE.AUMENTO}
                                                    color="primary"
                                                    checked={field.value === SINTOMA_SENSIBILIDADE.AUMENTO}
                                                    disabled={readOnly.sintomatologia}
                                                />
                                            }
                                            label={strings.aumentoSensibilidade}
                                        />
                                    </SttRadioGroup>
                                    {
                                        meta.touched && meta.error && 
                                        <SttFormHelperText error>
                                            {meta.error}
                                        </SttFormHelperText>
                                    }
                                </SttFormControl>
                            )}
                        </Field>
                    </SttGrid>
                </>
            }
            <SttGrid item xs={12}>
                <Field name={`${LESOES}[${indiceLesao.value}].${LESAO_SAI_RASPAGEM}`}>
                    {({
                        field,
                        meta,
                    }) => (
                        <SttFormControl error={meta.touched && meta.error ? true : false} variant="outlined">
                            <SttHeading variant="h4" color="primary"required={required.saiRaspagem}>{strings.lesaoSaiRaspagem}</SttHeading>
                            <SttRadioGroup row>
                                <SttFormControlLabel 
                                    {...field} 
                                    control={
                                        <SttRadioButton  
                                            type="radio"
                                            value={SAI_A_RASPAGEM.SIM}
                                            color="primary"
                                            checked={field.value === SAI_A_RASPAGEM.SIM}
                                            disabled={readOnly.sintomatologia}
                                        />
                                    }
                                    label={strings.sim}
                                />
                                <SttFormControlLabel 
                                    {...field} 
                                    control={
                                        <SttRadioButton  
                                            type="radio"
                                            value={SAI_A_RASPAGEM.NAO}
                                            color="primary"
                                            checked={field.value === SAI_A_RASPAGEM.NAO}
                                            disabled={readOnly.sintomatologia}
                                        />
                                    }
                                    label={strings.nao}
                                />
                            </SttRadioGroup>
                            {
                                meta.touched && meta.error && 
                                <SttFormHelperText error>
                                    {meta.error}
                                </SttFormHelperText>
                            }
                        </SttFormControl>
                    )}
                </Field>
            </SttGrid>
        </SttGrid>
    )
}

export default Form;
