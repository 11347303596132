export const FIELDS = {
    TABAGISMO: 'tabagismo',
    TABAGISMO_CIGARROS_DIA: 'tabagismoCigarrosDia',
    TABAGISMO_ANOS_FUMO: 'tabagismoAnosFumo',
    ETILISMO: 'etilismo',
    TIPO_BEBIDA: 'tipoBebida',
    TIPO_FUMO: 'tipoFumo',
    EXPOSICAO_SOL_HORAS_DIA: 'exposicaoSolHorasDia',
    EXPOSICAO_SOL_MESES_ANOS: 'exposicaoSolMesesAno',
    PROTECAO_SOLAR_TEMPO: 'protecaoSolarTempo',
    PROTECAO_SOLAR_FREQUENCIA: 'protecaoSolarFrequencia',
    OUTRAS_DROGAS: 'outrasDrogas',
    OUTRAS_DROGAS_TIPO: 'outrasDrogasTipo',
    OUTRAS_DROGAS_ANOS_USO: 'outrasDrogasAnosUso'
};