let dataDe = new Date();
let dataAte = new Date();
dataDe.setDate(dataAte.getDate() - 10);

export const estadoInicialForm = (config) => {
    return {
        uf: null,
        municipio: null,
        instituicao: null,
        rede: null,
        'funcionario-envio': '',
        requisicao: '',
        paciente: '',
        'data-exame-de': dataDe,
        'data-exame-ate': dataAte,
        'data-laudo-de': null,
        'data-laudo-ate': null,
        'data-invalidacao-de': null,
        'data-invalidacao-ate': null,
        'situacao-laudo': ['N', 'S', 'T', 'E', 'A'],
        atrasado: false,
        'ultrapassou-tempo-previsto': false,
        situacao: ['V'],
        protocolo: '',
        'numero-exame': '',
        page: config?.pagination.page || 0,
        count: config?.pagination.count || 10
    }
}