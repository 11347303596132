import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    open: false,
    titulo: '',
    tipo: 'alert',
    mensagem: '',
    options: [],
    onClose: null
};

export const alertaSlice = createSlice({
    name: 'alerta',
    initialState: INITIAL_STATE,
    reducers: {
        configure: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                ...payload
            };
        },
        open: (state, action) => {
            return {
                ...state,
                open: action.payload
            };
        }
    }
});

export const { configure, open } = alertaSlice.actions;

export default alertaSlice.reducer;
