import { temPerfilRBAC } from '../../secutity/acl';
import { PERFIL } from '../../common/Constants';
import Produtividade from '../../componentes/inicio/produtividade';
import LaudosGraves from '../../componentes/inicio/laudos-graves';
import usuario from '../../signals/usuario';

const PaginaInicial = () => {
    return (
        <>
            {
                (temPerfilRBAC(usuario, PERFIL.MEDICO_SOLICITANTE) || temPerfilRBAC(usuario, PERFIL.TECNICO)) &&
                <LaudosGraves />
            }
            <Produtividade />
        </>
        
    )
}

export default PaginaInicial;
