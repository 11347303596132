import { configureStore } from '@reduxjs/toolkit'
import indexReducer from '../reducers';
import envioImagensReducer from '../reducers/envio-imagens';
import alertaReducer from '../reducers/alerta';
import exameReducer from '../reducers/exame';
import laudoReducer from '../reducers/laudo';

export const store = configureStore({
    reducer: {
        index: indexReducer,
        'envio-imagens': envioImagensReducer,
        alerta: alertaReducer,
        exame: exameReducer,
        laudo: laudoReducer
    }
});
