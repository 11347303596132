import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { getHeaders } from '../../request';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import PrintIcon from '@material-ui/icons/Print';
import BlockIcon from '@material-ui/icons/Block';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WebIcon from '@material-ui/icons/Web';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Form from './formPesquisa';
import Moment from 'react-moment';
import Laudo from '../laudo';
import { TIPO_ABA_EXAME, SITUACAO_LAUDO, SITUACAO } from './constantes';
import { MODALIDADE, PERFIL, PERMISSOES } from '../../common/Constants';
import {
	temPerfilRBAC,
	temPermissaoRBAC,
	temPermissaoRede,
} from '../../secutity/acl';
import Priorizar from './priorizar';
import Invalidar from './invalidar';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import usuario from '../../signals/usuario';
import { listar, filtros } from '../../signals/exame';
import { laudar } from '../../signals/laudo';
import {
	SttTable,
	SttTableHead,
	SttTableBody,
	SttTableRow,
	SttTableCell,
	SttTablePagination,
	SttCircularProgress,
	SttMenu,
	SttMenuItem,
	SttListItemIcon,
	SttListItemText,
	SttDivider,
	SttGrid,
	SttTranslateHook,
	SttHidden,
	SttText,
} from '@stt-componentes/core';
import { Chip, IconButton } from '@material-ui/core';
import { ListaExames } from '@stt-utilitarios/core';

const useStyles = makeStyles((theme) => ({
	tableWrapper: {
		marginTop: theme.spacing(3),
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));

const Pesquisa = (props) => {
	const {
		tipo,
		id,
		laudador,
		imprimirFn,
		imprimirProtocoloFn,
		imprimirTermoFn,
	} = props;
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();
	const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

	const exames = useSignal([]);
	const buscaEmAndamento = useSignal(false);
	const page = useSignal(filtros.value.page || global.gConfig.pagination.page);
	const count = useSignal(
		filtros.value.count || global.gConfig.pagination.count
	);
	const totalRegistros = useSignal(0);
	const colspan = useSignal(6);
	const menuOptions = useSignal([]);
	const idExameSelecionado = useSignal(null);

	// Priorização
	const priorizar = useSignal(false);
	const idInstituicaoExamePriorizar = useSignal(null);

	// Invalidação
	const invalidar = useSignal(false);
	const idInstituicaoExameInvalidar = useSignal(null);
	const idModalidadeExameSelecionado = useSignal(null);

	const permissaoLaudoInvPrio = useSignal([]);

	// Redes
	const redes = useSignal([]);
	const carregarRedes = useSignal(false);

	useSignals();

	const preInvalidar = (exame) => {
		idExameSelecionado.value = exame.id;
		idInstituicaoExameInvalidar.value = exame.id_instituicao;
		idModalidadeExameSelecionado.value = exame.id_modalidade;
		invalidar.value = true;
	};

	const prePriorizar = (exame) => {
		idInstituicaoExamePriorizar.value = exame.id_instituicao;
		idExameSelecionado.value = exame.id;
		priorizar.value = true;
	};

	useSignalEffect(() => {
		if (carregarRedes.value) {
			axios
				.get(
					`${UTILITARIOS_API_BASE_URL}/rede?modalidade=${MODALIDADE.SIGLA}`,
					{ headers: getHeaders() }
				)
				.then((response) => {
					if (response.data) {
						const { itens } = response.data.data;
						redes.value = itens;
					}
				})
				.catch((err) => console.log(err));
		}
		if (usuario.value.perfisRBAC) {
			let rede;
			usuario.value.perfisRBAC.forEach((p) => {
				p.redes &&
					p.redes.forEach((r) => {
						if (r.id === id) {
							rede = r;
						}
					});
			});
			if (rede) {
				permissaoLaudoInvPrio.value = [
					temPermissaoRede(rede, PERMISSOES.INVALIDAR_EXAME),
					temPermissaoRede(rede, PERMISSOES.PRIORIZAR_EXAME),
					temPermissaoRede(rede, PERMISSOES.LAUDAR_EXAME),
				];
			}
		}
		if (
			temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) ||
			temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL)
		) {
			colspan.value = 9;
			carregarRedes.value = true;
		} else if (
			temPerfilRBAC(usuario, 'tecnico') ||
			temPerfilRBAC(usuario, 'medicoLaudador') ||
			temPerfilRBAC(usuario, 'medicoExecutor')
		) {
			colspan.value = 7;
		}

		let menu = [];
		if (
			temPermissaoRBAC(usuario, PERMISSOES.VISUALIZAR_EXAME) ||
			temPermissaoRBAC(usuario, PERMISSOES.VISUALIZAR_LAUDO)
		) {
			menu.push({
				apenasExameValido: false,
				aplicaA: [
					SITUACAO_LAUDO.COM_LAUDO,
					SITUACAO_LAUDO.SEM_LAUDO,
					SITUACAO_LAUDO.LAUDO_TEMPORARIO,
					SITUACAO_LAUDO.LAUDO_EMISSAO,
					SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
					SITUACAO_LAUDO.DIGITADO,
					SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
					SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO,
				],
				icon: VisibilityIcon,
				title: strings.ver,
				createOnClick: (exame) => {
					visualizarExame(exame.id);
				},
			});
		}
		if (permissaoLaudoInvPrio.value[2]) {
			menu.push({
				apenasExameValido: true,
				aplicaA: [SITUACAO_LAUDO.COM_LAUDO],
				icon: DescriptionIcon,
				title: strings.laudo,
				createOnClick: (exame) => {
					idExameSelecionado.value = exame.id;
					laudar.value = true;
				},
			});
		}
		if (
			(tipo === TIPO_ABA_EXAME.REDE && permissaoLaudoInvPrio.value[0]) ||
			(tipo !== TIPO_ABA_EXAME.REDE &&
				temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME))
		) {
			menu.push({
				apenasExameValido: true,
				aplicaA: [SITUACAO_LAUDO.SEM_LAUDO],
				icon: BlockIcon,
				title: strings.invalidar,
				createOnClick: (exame) => preInvalidar(exame),
			});
		}
		if (temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME_LAUDADO)) {
			menu.push({
				apenasExameValido: true,
				aplicaA: [SITUACAO_LAUDO.COM_LAUDO],
				icon: BlockIcon,
				title: strings.invalidar,
				createOnClick: (exame) => preInvalidar(exame),
			});
		}
		if (
			(tipo === TIPO_ABA_EXAME.REDE && permissaoLaudoInvPrio.value[1]) ||
			(tipo !== TIPO_ABA_EXAME.REDE &&
				temPermissaoRBAC(usuario, PERMISSOES.PRIORIZAR_EXAME))
		) {
			menu.push({
				apenasExameValido: true,
				aplicaA: [SITUACAO_LAUDO.SEM_LAUDO],
				icon: PriorityHighIcon,
				title: strings.priorizar,
				createOnClick: (exame) => prePriorizar(exame),
			});
		}
		menu.push({
			apenasExameValido: true,
			aplicaA: [
				SITUACAO_LAUDO.COM_LAUDO,
				SITUACAO_LAUDO.SEM_LAUDO,
				SITUACAO_LAUDO.LAUDO_TEMPORARIO,
				SITUACAO_LAUDO.LAUDO_EMISSAO,
				SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
				SITUACAO_LAUDO.DIGITADO,
				SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
				SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO,
			],
			icon: PrintIcon,
			title: strings.imprimir,
			createOnClick: (exame) => imprimirFn(exame.id),
		});
		menu.push({
			apenasExameValido: true,
			aplicaA: [
				SITUACAO_LAUDO.COM_LAUDO,
				SITUACAO_LAUDO.SEM_LAUDO,
				SITUACAO_LAUDO.LAUDO_TEMPORARIO,
				SITUACAO_LAUDO.LAUDO_EMISSAO,
				SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
				SITUACAO_LAUDO.DIGITADO,
				SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
				SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO,
			],
			icon: WebIcon,
			title: strings.protocolo,
			createOnClick: (exame) => imprimirProtocoloFn(exame),
		});
		menu.push({
			apenasExameValido: true,
			aplicaA: [
				SITUACAO_LAUDO.COM_LAUDO,
				SITUACAO_LAUDO.SEM_LAUDO,
				SITUACAO_LAUDO.LAUDO_TEMPORARIO,
				SITUACAO_LAUDO.LAUDO_EMISSAO,
				SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
				SITUACAO_LAUDO.DIGITADO,
				SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
				SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO,
			],
			icon: AssignmentIcon,
			title: strings.imprimirTermo,
			createOnClick: (exame) => imprimirTermoFn(exame),
		});
		menuOptions.value = menu;

		if (location.state?.from === 'visualizar') {
			listar.value = true;
		}
	});

	useSignalEffect(() => {
		if (!laudar.value && !priorizar.value && !invalidar.value) {
			idExameSelecionado.value = null;
			idInstituicaoExameInvalidar.value = null;
			idModalidadeExameSelecionado.value = null;
		}
	});

	const visualizarExame = (id) => {
		navigate('/exames');
		navigate('/exames/visualizar', { state: { exame: id } });
		laudar.value = false;
	};

	const resetPageCount = () => {
		page.value = global.gConfig.pagination.page;
		count.value = global.gConfig.pagination.count;
	};

	const handleChangePage = (event, newPage) => {
		page.value = newPage;
		listar.value = true;
	};

	const handleChangeRowsPerPage = (event) => {
		count.value = event.target.value;
		page.value = global.gConfig.pagination.page;
		listar.value = true;
	};

	const callbackBusca = (dados) => {
		if (dados) {
			totalRegistros.value = parseInt(dados.totalRegistros);
			exames.value = dados.itens;
		} else {
			totalRegistros.value = 0;
			exames.value = [];
		}
		listar.value = false;
	};

	return (
		<>
			{(laudar.value && <Laudo idExame={idExameSelecionado.value} />) || (
				<>
					<Form
						tipoAba={tipo}
						idAba={id}
						laudador={laudador}
						callbackBusca={callbackBusca}
						callbackAndamento={(b) => (buscaEmAndamento.value = b)}
						page={page}
						count={count}
						resetPageCount={resetPageCount}
						redes={redes.value}
					/>

					<SttGrid container spacing={3}>
						<SttGrid item xs={12}>
							<SttDivider />
							<div className={classes.tableWrapper}>
								<SttTable>
									<SttTableHead>
										<SttTableRow>
											<SttHidden smDown>
												<SttTableCell>{strings.dataExame}</SttTableCell>
												{(temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) ||
													temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL)) && (
													<>
														<SttTableCell>
															{`${strings.municipio}/${strings.uf}`}
														</SttTableCell>
														<SttTableCell>{strings.instituicao}</SttTableCell>
													</>
												)}
												{(temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) ||
													temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL) ||
													temPerfilRBAC(usuario, PERFIL.TECNICO) ||
													temPerfilRBAC(usuario, PERFIL.MEDICO_LAUDADOR)) && (
													<SttTableCell>{strings.tecnico}</SttTableCell>
												)}
												<SttTableCell>{strings.requisicao}</SttTableCell>
												<SttTableCell>{strings.paciente}</SttTableCell>
												<SttTableCell>{strings.descricao}</SttTableCell>
												<SttTableCell>{strings.situacao}</SttTableCell>
												<SttTableCell align='center'>
													{strings.opcoes}
												</SttTableCell>
											</SttHidden>
										</SttTableRow>
										<SttTableRow>
											<SttHidden mdUp>
												{temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) ||
													temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL) ||
													temPerfilRBAC(usuario, PERFIL.TECNICO) ||
													temPerfilRBAC(usuario, PERFIL.MEDICO_LAUDADOR)}
												<SttTableCell width='100%'>
													{strings.dadosExame}
												</SttTableCell>
												<SttTableCell align='center'>
													{strings.opcoes}
												</SttTableCell>
											</SttHidden>
										</SttTableRow>
									</SttTableHead>
									<SttTableBody>
										{buscaEmAndamento.value ? (
											<SttTableRow key={-1}>
												<SttTableCell colSpan={colspan.value} align='center'>
													<SttCircularProgress />
												</SttTableCell>
											</SttTableRow>
										) : exames.value.length === 0 ? (
											<SttTableRow key={-1}>
												<SttTableCell colSpan={colspan.value} align='center'>
													{strings.nenhumRegistroEncontrado}
												</SttTableCell>
											</SttTableRow>
										) : (
											exames.value.map((row, index) => (
												<SttTableRow key={index}>
													<SttHidden smDown>
														<SttTableCell>
															{
																<Moment format='DD/MM/YYYY HH:mm'>
																	{row.data_exame}
																</Moment>
															}
														</SttTableCell>
														{(temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) ||
															temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL)) && (
															<>
																<SttTableCell>{`${row.municipio}/${row.uf}`}</SttTableCell>
																<SttTableCell>{row.instituicao}</SttTableCell>
															</>
														)}
														{(temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) ||
															temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL) ||
															temPerfilRBAC(usuario, PERFIL.TECNICO) ||
															temPerfilRBAC(usuario, PERFIL.MEDICO_LAUDADOR)) && (
															<SttTableCell>
																{row.funcionario_envio}
															</SttTableCell>
														)}
														<SttTableCell>{row.requisicao}</SttTableCell>
														<SttTableCell>{row.paciente}</SttTableCell>
														<SttTableCell>{row.descricao_exame}</SttTableCell>
														<SttTableCell>
															<Chip
																style={{backgroundColor:ListaExames.getCorLegenda(row)}}
																label={ListaExames.getTextoLegenda(row, strings)}
																size='small'
															/>
														</SttTableCell>
														<SttTableCell align='center'>
															{menuOptions.value.length > 0 && (
																<PopupState variant='popover'>
																	{(popupState) => (
																		<>
																			<IconButton
																				aria-haspopup='true'
																				{...bindTrigger(popupState)}
																			>
																				<MoreVertIcon />
																			</IconButton>
																			<SttMenu {...bindMenu(popupState)}>
																				{menuOptions.value.map((item, idx) => {
																					if (
																						item.aplicaA.includes(
																							row.situacao_laudo
																						) &&
																						((row.situacao ===
																							SITUACAO.VALIDO &&
																							item.apenasExameValido) ||
																							!item.apenasExameValido)
																					) {
																						return (
																							<SttMenuItem
																								key={idx}
																								onClick={() => {
																									popupState.close();
																									item.createOnClick(row);
																								}}
																							>
																								<SttListItemIcon>
																									<item.icon />
																								</SttListItemIcon>
																								<SttListItemText
																									primary={item.title}
																								/>
																							</SttMenuItem>
																						);
																					}
																				})}
																			</SttMenu>
																		</>
																	)}
																</PopupState>
															)}
														</SttTableCell>
													</SttHidden>
													<SttHidden mdUp>
														<SttTableCell>
															<SttText size='small'>
																<b>{strings.data}: </b>
																<Moment format='DD/MM/YYYY HH:mm'>
																	{row.data_exame}
																</Moment>
															</SttText>
															<SttText size='small'>
																{(temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) ||
																temPerfilRBAC(usuario,PERFIL.ADMINISTRADOR_ESTADUAL)) && (
																	<>
																		<SttText size='small'>
																			<b>{`${strings.municipio}/${strings.uf}`}: </b> {`${row.municipio}/${row.uf}`}
																		</SttText>
                                                                        <SttText size='small'>
                                                                            <b>{strings.instituicao}: </b> {row.instituicao}
                                                                        </SttText>
																	</>
																)}
															</SttText>
                                                            {(temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR) ||
															temPerfilRBAC(usuario, PERFIL.ADMINISTRADOR_ESTADUAL) ||
															temPerfilRBAC(usuario, PERFIL.TECNICO) ||
															temPerfilRBAC(usuario, PERFIL.MEDICO_LAUDADOR)) && row.funcionario_envio && (
                                                                <SttText size='small'>
                                                                    <b>
                                                                        {strings.tecnico}: </b>
                                                                    {row.funcionario_envio}
                                                                </SttText>
                                                            )}
                                                            <SttText size='small'>
                                                                <b>{strings.requisicao}: </b> {row.requisicao}
                                                            </SttText>
                                                            <SttText size='small'>
                                                                <b>{strings.paciente}: </b> {row.paciente}
                                                            </SttText>
                                                            <SttText size='small'>
                                                                <b>{strings.descricao}: </b> {row.descricao_exame}
                                                            </SttText>
                                                            <SttText size='small'>
                                                                <b>{strings.situacao}:</b>
                                                                <Chip
																style={{backgroundColor: ListaExames.getCorLegenda(row)}}
																label={ListaExames.getTextoLegenda(row,strings)}
																size='small'
															/>
                                                            </SttText>
                                                            
														</SttTableCell>
                                                        <SttTableCell align='center'>
															{menuOptions.value.length > 0 && (
																<PopupState variant='popover'>
																	{(popupState) => (
																		<>
																			<IconButton
																				aria-haspopup='true'
																				{...bindTrigger(popupState)}
																			>
																				<MoreVertIcon />
																			</IconButton>
																			<SttMenu {...bindMenu(popupState)}>
																				{menuOptions.value.map((item, idx) => {
																					if (
																						item.aplicaA.includes(
																							row.situacao_laudo
																						) &&
																						((row.situacao ===
																							SITUACAO.VALIDO &&
																							item.apenasExameValido) ||
																							!item.apenasExameValido)
																					) {
																						return (
																							<SttMenuItem
																								key={idx}
																								onClick={() => {
																									popupState.close();
																									item.createOnClick(row);
																								}}
																							>
																								<SttListItemIcon>
																									<item.icon />
																								</SttListItemIcon>
																								<SttListItemText
																									primary={item.title}
																								/>
																							</SttMenuItem>
																						);
																					}
																				})}
																			</SttMenu>
																		</>
																	)}
																</PopupState>
															)}
														</SttTableCell>
													</SttHidden>
												</SttTableRow>
											))
										)}
									</SttTableBody>
								</SttTable>
							</div>
						</SttGrid>
					</SttGrid>
					{exames.value.length > 0 ? (
						<>
							<SttTablePagination
								rowsPerPageOptions={[10, 20, 40]}
								component='div'
								count={totalRegistros.value}
								rowsPerPage={count.value}
								page={page.value}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelRowsPerPage={strings.linhasPorPagina}
								labelDisplayedRows={(params) =>
									`${params.from}-${params.to} ${strings.de} ${params.count}`
								}
							/>
							{((tipo === TIPO_ABA_EXAME.REDE &&
								permissaoLaudoInvPrio.value[1]) ||
								(tipo !== TIPO_ABA_EXAME.REDE &&
									temPermissaoRBAC(usuario, PERMISSOES.PRIORIZAR_EXAME))) && (
								<Priorizar
									priorizar={priorizar.value}
									setPriorizar={(p) => (priorizar.value = p)}
									idExame={idExameSelecionado.value}
									idInstituicao={idInstituicaoExamePriorizar.value}
								/>
							)}
							{invalidar.value &&
								((tipo === TIPO_ABA_EXAME.REDE &&
									permissaoLaudoInvPrio.value[0]) ||
									(tipo !== TIPO_ABA_EXAME.REDE &&
										temPermissaoRBAC(usuario, PERMISSOES.INVALIDAR_EXAME))) && (
									<Invalidar
										invalidar={invalidar.value}
										setInvalidar={(i) => (invalidar.value = i)}
										idExame={idExameSelecionado.value}
										idInstituicao={idInstituicaoExameInvalidar.value}
										idModalidade={idModalidadeExameSelecionado.value}
									/>
								)}
						</>
					) : null}
				</>
			)}
		</>
	);
};

Pesquisa.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	tipo: PropTypes.string.isRequired,
};

export default Pesquisa;
