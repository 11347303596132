export const PERFIL = {
    MEDICO_SOLICITANTE: 'medicoSolicitante',
    MEDICO_LAUDADOR: 'medicoLaudador',
    ADMINISTRADOR: 'administrador',
    TECNICO: 'tecnico',
    REGULADOR_EXAME: 'reguladorExame',
    VISUALIZADOR: 'visualizador',
    VISUALIZADOR_REDE: 'visualizadorRede',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
};

export const PERMISSOES = {
    ESTOMATO: 'ESTOMATO_ESTOMATO',
    SOLICITAR_EXAME: 'ESTOMATO_SOLICITAR_EXAME',
    VISUALIZAR_SOLICITACAO: 'ESTOMATO_VISUALIZAR_SOLIC_EX',
    CRIAR_EXAME: 'ESTOMATO_CRIAR_EXAME',
    VISUALIZAR_EXAME: 'ESTOMATO_VISUALIZAR_EXAME',
    VISUALIZAR_LAUDO: 'ESTOMATO_VISUALIZAR_LAUDO',
    INVALIDAR_EXAME: 'ESTOMATO_INVALIDAR_EXAME',
    INVALIDAR_EXAME_LAUDADO: 'ESTOMATO_INVALIDAR_EXAME_LAUD',
    PRIORIZAR_EXAME: 'ESTOMATO_PRIORIZAR_EXAME',
    LAUDAR_EXAME: 'ESTOMATO_LAUDAR_EXAME',
    LAUDAR_EXAME_FABRICA: 'ESTOMATO_LAUDAR_EXAME_FABRICA',
    VISUALIZAR_IMAGEM: 'ESTOMATO_VISUALIZAR_IMAGEM'
};

export const MODALIDADE = {
    SIGLA: 'EST',
    DESCRICAO: 'Estomatologia',
    CODIGO: '1.2.840.10008.5.1.4.1.1.77.1.4.1',
    DESCRICAO_PT: 'Estomatologia'
};

export const ORIGEM_MANUAL = 'M';

export const SIM = 'S';

export const NAO = 'N';

export const TABAGISMO = {
    FUMANTE: 'S',
    EX_FUMANTE: 'E',
    NUNCA_FUMOU: 'N'
};

export const ETILISMO = {
    NUNCA: 0, 
    MENSALMENTE_MENOS: 1, 
    DUAS_QUATRO_VEZES_MES: 2, 
    DUAS_TRES_VEZES_SEMANA: 3, 
    QUATRO_OU_MAIS_VEZES_SEMANA: 4
};

export const OUTRAS_DROGAS = {
    USUARIO: 'S',
    EX_USUARIO: 'E',
    NAO_USUARIO: 'N'
};

export const TRATAMENTO_PREVIO = {
    SIM: 'S',
    NAO: 'N'
};

export const USUARIO_PROTESE = {
    SIM: 'S',
    NAO: 'N'
};

export const LESAO_SAI_RASPAGEM = {
    SIM: 'S',
    NAO: 'N'
};

export const SITUACAO_PROTESE = {
    NOVA: 'N',
    ANTIGA: 'A'
};

export const ABRANGENCIA_PROTESE = {
    TOTAL: 'T',
    PARCIAL: 'P'
};

export const POSSIBILIDADE_TRAUMA_LOCAL = {
    MORDEDURA: 'M',
    PROTESE_MAL_ADAPTADA: 'P',
    SEM_TRAUMA: 'S',
    OUTRA: 'O'
};

export const LADO_AFETADO = {
    UNILATERAL: 'U',
    BILATERAL: 'B',
    LINHA_MEDIA: 'L',
    NAO_APLICA: 'N'
};

export const ARCADA_AFETADA = {
    SUPERIOR: 'S',
    INFERIOR: 'I',
    AMBAS: 'A',
    NAO_APLICA: 'N'
};

export const OCORRENCIA_LESAO = {
    UNICA: 'U',
    MULTIPLAS: 'M',
    SEM_LESAO: 'N'
};

export const UNIDADE_MEDIDA = {
    MM: 'mm',
    CM: 'cm'
};

export const PACIENTE_ASSINTOMATICO = {
    SIM: 'S',
    NAO: 'N'
};

export const LOCALIZACAO_DOR = {
    LOCALIZADA: 'L',
    DIFUSA: 'D'
};

export const ESPONTANEIDADE_DOR = {
    'ESPONTÂNEA': 'E',
    PROVOCADA: 'P'
};

export const DURACAO_DOR = {
    PASSAGEIRA: 'P',
    'CONTÍNUA': 'C',
    INTERMITENTE: 'I'
};

export const INTENSIDADE_DOR = {
    LEVE: 'L',
    MODERADA: 'M',
    INTENSA: 'I'
};

export const TIPO_DOR = {
    'PULSÁTIL': 'U',
    'NÃO PULSÁTIL': 'N'
};

export const QUEIMACAO = {
    SIM: 'S',
    NAO: 'N'
};

export const SENSIBILIDADE = {
    NAO: 'N',
    PERDA: 'P',
    AUMENTO: 'A'
};

export const SAI_A_RASPAGEM = {
    SIM: 'S',
    NAO: 'N'
};

export const VERSAO = {
    ANTIGA: 1,
    NOVA: 2
};
