import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal } from '@preact/signals-react';

import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttSeeButton,
    SttHidden,
    SttText, 
    SttTranslateHook
} from '@stt-componentes/core';

import Form from './form';
import Detalhes from './detalhes';
import { listar } from '../../../signals/envio-imagens';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        DATA: 'data',
        MODALIDADE: 'modalidade',
        SOLICITANTE: 'solicitante',
        PACIENTE: 'nome_paciente',
        CIDADE_PROCEDENCIA: 'nome_cidade_procedencia'
    }
}

const SolicitacoesEmAberto = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const solicitacoes = useSignal([]);
    const buscaEmAndamento = useSignal(false);
    const page = useSignal(global.gConfig.pagination.page);
    const count = useSignal(global.gConfig.pagination.count);
    const totalRegistros = useSignal(0);
    const order = useSignal(ORDENACAO.DIRECAO.ASC);
    const orderBy = useSignal(null);
    const idSolicitacaoSelecionada = useSignal(null);

    useSignals();

    const resetPageOrder = () => {
        page.value = global.gConfig.pagination.page;
        count.value = global.gConfig.pagination.count;
        order.value = ORDENACAO.DIRECAO.ASC;
        orderBy.value = null;
    }

    const handleClickSort = (campo) => {
        const isAsc = (orderBy.value === campo) && (order.value === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        order.value = newOrder;
        orderBy.value = campo;
        listar.value = true;
    }

    const handleChangePage = (event, newPage) => {
        page.value = newPage;
        listar.value = true;
    };

    const handleChangeRowsPerPage = event => {
        count.value = event.target.value;
        page.value = global.gConfig.pagination.page;
        listar.value = true;
    };

    const callbackBusca = (dados) => {
        if (dados) {
            totalRegistros.value = parseInt(dados.totalRegistros);
            solicitacoes.value = dados.itens;
        }
        listar.value = false;
    }

    const handleClickVoltar = (atualizarBusca) => {
        idSolicitacaoSelecionada.value = null;
        if (atualizarBusca) {
            listar.value = true;
        }
    }

    return (
        <>
            {
                !idSolicitacaoSelecionada.value
                ?
                (
                    <>
                        <Form 
                            strings={strings} 
                            callbackBusca={callbackBusca} 
                            buscaEmAndamento={buscaEmAndamento} 
                            page={page.value} 
                            count={count.value} 
                            orderBy={orderBy.value} 
                            order={order.value} 
                            resetPageOrder={resetPageOrder}
                        />
            
                        <div className={classes.tableWrapper}>
                            <SttTable>
                                <SttTableHead>
                                    <SttTableRow>
                                        <SttHidden smDown>
                                            <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.DATA ? order : false}>
                                                <SttTableSortLabel
                                                    active={orderBy === ORDENACAO.CAMPOS.DATA}
                                                    direction={orderBy === ORDENACAO.CAMPOS.DATA ? order : ORDENACAO.DIRECAO.ASC}
                                                    onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA)}
                                                >
                                                    {strings.dataSolicitacao}
                                                </SttTableSortLabel>
                                            </SttTableCell>
                                            <SttTableCell width="25%" sortDirection={orderBy === ORDENACAO.CAMPOS.SOLICITANTE ? order : false}>
                                                <SttTableSortLabel
                                                    active={orderBy === ORDENACAO.CAMPOS.SOLICITANTE}
                                                    direction={orderBy === ORDENACAO.CAMPOS.SOLICITANTE ? order : ORDENACAO.DIRECAO.ASC}
                                                    onClick={() => handleClickSort(ORDENACAO.CAMPOS.SOLICITANTE)}
                                                >
                                                    {strings.solicitante}
                                                </SttTableSortLabel>
                                            </SttTableCell>
                                            <SttTableCell width="25%" sortDirection={orderBy === ORDENACAO.CAMPOS.PACIENTE ? order : false}>
                                                <SttTableSortLabel
                                                    active={orderBy === ORDENACAO.CAMPOS.PACIENTE}
                                                    direction={orderBy === ORDENACAO.CAMPOS.PACIENTE ? order : ORDENACAO.DIRECAO.ASC}
                                                    onClick={() => handleClickSort(ORDENACAO.CAMPOS.PACIENTE)}
                                                >
                                                    {strings.paciente}
                                                </SttTableSortLabel>
                                            </SttTableCell>
                                            <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA ? order : false}>
                                                <SttTableSortLabel
                                                    active={orderBy === ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA}
                                                    direction={orderBy === ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA ? order : ORDENACAO.DIRECAO.ASC}
                                                    onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA)}
                                                >
                                                    {`${strings.municipio}/${strings.uf}`}
                                                </SttTableSortLabel>
                                            </SttTableCell>
                                            <SttTableCell width="5%">
                                                {strings.visualizar}
                                            </SttTableCell>
                                        </SttHidden>
                                        <SttHidden mdUp>
                                            <SttTableCell width="100%" colSpan="2">Solicitações de exames</SttTableCell>
                                        </SttHidden>
                                    </SttTableRow>
                                </SttTableHead>
                                <SttTableBody>
                                    {
                                        buscaEmAndamento.value
                                        ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={6} 
                                                align="center">
                                                <SttCircularProgress />
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        (
                                            solicitacoes.value.length === 0 ? 
                                                <SttTableRow key={-1}>
                                                    <SttTableCell colSpan={5} 
                                                        align="center">
                                                        {strings.nenhumRegistroEncontrado}
                                                    </SttTableCell>
                                                </SttTableRow>
                                            : 
                                            solicitacoes.value.map((row, index) => (
                                                <SttTableRow key={index}>
                                                    <SttHidden smDown>
                                                        <SttTableCell>{`${row.data_formatada} ${global.gConfig.sufixo_data_hora}`}</SttTableCell>
                                                        <SttTableCell>{row.solicitante}</SttTableCell>
                                                        <SttTableCell>{row.nome_paciente}</SttTableCell>
                                                        <SttTableCell>{row.nome_cidade_estado_procedencia}</SttTableCell>
                                                    </SttHidden>
                                                    <SttHidden mdUp>
                                                        <SttTableCell width="96%">
                                                            <SttText size="small">
                                                                <b>{strings.data}:</b> {row.data_formatada}
                                                            </SttText>
                                                            <SttText size="small">
                                                                <b>{strings.modalidade}:</b> {row.modalidade}
                                                            </SttText>
                                                            <SttText size="small">
                                                                <b>{strings.solicitante}:</b> {row.solicitante}
                                                            </SttText>
                                                            <SttText size="small">
                                                                <b>{strings.paciente}:</b> {row.nome_paciente}
                                                            </SttText>
                                                            <SttText size="small">
                                                                <b>{`${strings.municipio}/${strings.uf}`}:</b> {row.nome_cidade_estado_procedencia}
                                                            </SttText>
                                                        </SttTableCell>
                                                    </SttHidden>
                                                    <SttTableCell align="center">
                                                        <SttSeeButton 
                                                            id={`btn-ver-solicitacao-${index}`} 
                                                            onClick={() => idSolicitacaoSelecionada.value = row.id}
                                                        />
                                                    </SttTableCell>
                                                </SttTableRow>
                                            ))
                                        )
                                    }
                                </SttTableBody>
                            </SttTable>
                        </div>
                        {
                            solicitacoes.value.length > 0 ?
                            (
                                <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                                    component="div"
                                    count={totalRegistros.value} 
                                    rowsPerPage={count.value} 
                                    page={page.value}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage={strings.linhasPorPagina}
                                    labelDisplayedRows={(params) => `${params.from}-${params.to} ${strings.de} ${params.count}`}
                                />
                            ) : null
                        }  
                    </>
                )
                :
                <Detalhes id={idSolicitacaoSelecionada.value} voltar={handleClickVoltar} />
            }
        </>
    )
}

export default SolicitacoesEmAberto;
